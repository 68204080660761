var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: true },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("closeModal")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f3 light-gray fw5 di" }),
      ]),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          {
            staticClass: "w-100 f5 pb4 white",
            staticStyle: { width: "450px" },
          },
          [
            _c("div", { staticClass: "pb3" }, [
              _vm._v("Choose the asset type that you want to generate"),
            ]),
            _c("div", { staticClass: "flex justify-between" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-column items-center ba b--silver br3 pa4 dim w4 h4 mr3 pointer",
                  class: _vm.selectedMode === "images" && "b--white bw2",
                  on: {
                    click: function ($event) {
                      _vm.selectedMode = "images"
                    },
                  },
                },
                [
                  _c(
                    "i",
                    {
                      staticClass: "material-icons f3 light-gray v-btm mb3 dim",
                    },
                    [_vm._v("burst_mode")]
                  ),
                  _c("span", { staticClass: "tc" }, [_vm._v("Stock Images")]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-column items-center ba b--silver br3 pa4 dim w4 h4 mr3 pointer",
                  class: _vm.selectedMode === "ai" && "b--white bw2",
                  on: {
                    click: function ($event) {
                      _vm.selectedMode = "ai"
                    },
                  },
                },
                [
                  _c(
                    "i",
                    {
                      staticClass: "material-icons f3 light-gray v-btm mb3 dim",
                    },
                    [_vm._v("auto_awesome")]
                  ),
                  _c("span", { staticClass: "tc" }, [_vm._v("AI Images")]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-column items-center ba b--silver br3 pa4 dim w4 h4 pointer",
                  class: _vm.selectedMode === "videos" && "b--white bw2",
                  on: {
                    click: function ($event) {
                      _vm.selectedMode = "videos"
                    },
                  },
                },
                [
                  _c(
                    "i",
                    {
                      staticClass: "material-icons f3 light-gray v-btm mb3 dim",
                    },
                    [_vm._v("slideshow")]
                  ),
                  _c("span", { staticClass: "tc" }, [_vm._v("Stock Videos")]),
                ]
              ),
            ]),
            _vm.selectedMode === "ai"
              ? _c("div", [
                  _c("div", { staticClass: "pt4 pb2" }, [
                    _vm._v("Choose orientation"),
                  ]),
                  _c("div", { staticClass: "flex justify-between" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-column items-center ba b--silver br3 pa4 dim w4 h4 mr3 pointer",
                        class:
                          _vm.selectedOrientation === "square" &&
                          "b--white bw2",
                        on: {
                          click: function ($event) {
                            _vm.selectedOrientation = "square"
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "flex justify-center" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "bn w-46 h-46 landscape bg-center mr1 pa2",
                            },
                            [
                              _c("div", {
                                staticClass:
                                  "w-100 h-100 bg-adori-very-light-gray",
                              }),
                            ]
                          ),
                        ]),
                        _c("span", { staticClass: "tc" }, [_vm._v("Square")]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-column items-center ba b--silver br3 pa4 dim w4 h4 mr3 pointer",
                        class:
                          _vm.selectedOrientation === "landscape" &&
                          "b--white bw2",
                        on: {
                          click: function ($event) {
                            _vm.selectedOrientation = "landscape"
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "flex justify-center" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "bn w-46 h-46 landscape bg-center mr1 pv2",
                            },
                            [
                              _c("div", {
                                staticClass:
                                  "w-100 h-100 bg-adori-very-light-gray",
                              }),
                            ]
                          ),
                        ]),
                        _c("span", { staticClass: "tc" }, [
                          _vm._v("Landscape"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-column items-center ba b--silver br3 pa4 dim w4 h4 pointer",
                        class:
                          _vm.selectedOrientation === "portrait" &&
                          "b--white bw2",
                        on: {
                          click: function ($event) {
                            _vm.selectedOrientation = "portrait"
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "flex justify-center" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "bn w-46 h-46 landscape bg-center mr1 ph2",
                            },
                            [
                              _c("div", {
                                staticClass:
                                  "w-100 h-100 bg-adori-very-light-gray",
                              }),
                            ]
                          ),
                        ]),
                        _c("span", { staticClass: "tc" }, [_vm._v("Portrait")]),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.selectedMode === "images" ||
            _vm.selectedMode === "videos" ||
            (_vm.selectedMode === "ai" && _vm.selectedOrientation)
              ? _c(
                  "div",
                  { staticClass: "flex justify-center" },
                  [
                    _c("BaseButtonRed", {
                      staticClass: "mt4 z-999",
                      attrs: {
                        text: "Auto Generate",
                        onClick: _vm.automate,
                        icon: "auto_awesome",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ]),
      _c("template", { slot: "footer" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }