var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "flex justify-between items-center" }, [
        !["custom"].includes(_vm.openAiOption)
          ? _c(
              "div",
              {
                staticClass:
                  "ba bw1 br4 b--transparent bg-black-20 flex items-center",
              },
              _vm._l(_vm.searchLibraries, function (item) {
                return _c(
                  "label",
                  {
                    staticClass: "f6 pv1 ph2 silver link pointer mr1",
                    class:
                      _vm.selectedImageTab === item.LibraryName
                        ? "ba br4 b--transparent bg-black-80 light-gray"
                        : "",
                  },
                  [
                    _c("input", {
                      staticClass: "input-reset",
                      attrs: { type: "radio" },
                      domProps: {
                        checked: _vm.selectedImageTab === item.LibraryName,
                      },
                      on: {
                        click: function ($event) {
                          _vm.selectedImageTab = item.LibraryName
                        },
                      },
                    }),
                    _vm._v(_vm._s(item.name)),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm.selectedImageTab === "LINKS"
        ? _c("div", [
            _vm.tabType === "transcript"
              ? _c(
                  "div",
                  [
                    _c("TagSuggestionsLinks", {
                      attrs: {
                        type: "transcript",
                        title: "Suggested links",
                        filter: _vm.filter,
                        links: _vm.suggestedURLs,
                        audioUid: _vm.audioUid,
                        currentTime: _vm.currentTime,
                        onCurrentTime: _vm.onCurrentTime,
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("TagSuggestionsLinks", {
                      attrs: {
                        type: "episode",
                        title: "Detected links",
                        links: _vm.detectedURLs,
                        audioUid: _vm.audioUid,
                        currentTime: _vm.currentTime,
                        onCurrentTime: _vm.onCurrentTime,
                      },
                    }),
                    _c("TagSuggestionsLinks", {
                      attrs: {
                        type: "episode",
                        title: "Suggested links",
                        links: _vm.suggestedURLs,
                        audioUid: _vm.audioUid,
                        currentTime: _vm.currentTime,
                        onCurrentTime: _vm.onCurrentTime,
                      },
                    }),
                  ],
                  1
                ),
          ])
        : _vm._e(),
      _vm.openAiOption === "custom"
        ? _c(
            "div",
            { staticClass: "flex items-center w-100" },
            [
              _c("div", { staticClass: "w-90 relative" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.openAiPrompt,
                      expression: "openAiPrompt",
                    },
                  ],
                  staticClass:
                    "input-reset bg-dark-gray light-gray bn pv2 br2 pl2 pr2 w-100 f6",
                  attrs: { type: "text", placeholder: "Enter the prompt" },
                  domProps: { value: _vm.openAiPrompt },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.openAiPrompt = $event.target.value
                    },
                  },
                }),
                !_vm.openAiPrompt
                  ? _c(
                      "i",
                      {
                        staticClass: "material-icons silver absolute f4",
                        staticStyle: { top: "5px", right: "5px" },
                      },
                      [_vm._v("search")]
                    )
                  : _c(
                      "i",
                      {
                        staticClass:
                          "material-icons silver absolute pointer dim f4",
                        staticStyle: { top: "5px", right: "5px" },
                        on: { click: _vm.clearOpenAiPrompt },
                      },
                      [_vm._v("close")]
                    ),
              ]),
              _c("BaseButtonBorder", {
                staticClass: "ml3",
                attrs: { text: "search", onClick: _vm.customOpenAiSearch },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isFetchingImages
        ? _c("div", { staticClass: "silver pt3" }, [_vm._v("Searching...")])
        : ["poll", "text"].includes(_vm.openAiOption)
        ? _c(
            "div",
            { staticClass: "flex flex-wrap mt3" },
            [
              _vm._l(_vm.tags, function (tag) {
                return _c(
                  "div",
                  { key: tag.id, staticClass: "relative" },
                  [
                    _c("AdoriTag", {
                      staticClass: "smaller size mr3 mb3",
                      attrs: {
                        tagData: tag,
                        filter: tag.orientation,
                        smallTag: true,
                        hasButtons: "",
                      },
                    }),
                    _c(
                      "i",
                      {
                        staticClass:
                          "material-icons bg-dark-gray br-100 pa1 light-gray f4 absolute pointer",
                        staticStyle: { top: "15px", right: "25px" },
                        on: {
                          click: function ($event) {
                            return _vm.onCreateTagFromUnsplash(tag.image)
                          },
                        },
                      },
                      [_vm._v("\n        add\n      ")]
                    ),
                  ],
                  1
                )
              }),
              _vm.tags.length === 0
                ? _c("div", { staticClass: "silver pt3" }, [
                    _vm._v("No images found."),
                  ])
                : _vm._e(),
            ],
            2
          )
        : ((_vm.imageSearchResult &&
            !_vm.imageSearchResult[_vm.selectedImageTab.toLowerCase()]) ||
            (_vm.imageSearchResult[_vm.selectedImageTab.toLowerCase()].data
              .length === 0 &&
              _vm.hasSearched)) &&
          "custom" !== _vm.openAiOption
        ? _c("div", { staticClass: "silver pt3" }, [
            _vm._v("\n    No images found.\n  "),
          ])
        : _vm.imageSearchResult[_vm.selectedImageTab.toLowerCase()] &&
          _vm.imageSearchResult[_vm.selectedImageTab.toLowerCase()].data &&
          _vm.imageSearchResult[_vm.selectedImageTab.toLowerCase()].data
            .length !== 0 &&
          "custom" !== _vm.openAiOption
        ? _c("ImagesList", {
            attrs: {
              isMaximized: _vm.isMaximized,
              selectedRangeStart: _vm.selectedRangeStart,
              selectedRangeEnd: _vm.selectedRangeEnd,
              showCreateTagButton: "true",
              totalImages: _vm.totalImages,
              previewImageSrc: _vm.previewImageSrc,
              imageSources: _vm.imageSources,
            },
            on: {
              handleNavigation: _vm.hasItemsLeftLoad,
              handleSelectImage: _vm.onCreateTagFromUnsplash,
            },
          })
        : _vm._e(),
      "custom" === _vm.openAiOption && !_vm.isFetchingImages
        ? _c("div", { staticClass: "white mt3" }, [
            _c("span", { staticClass: "fw5" }, [_vm._v(" Open Ai Result: ")]),
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.openAiResults.result.replace(/\r?\n/gi, "<br/>")
                ),
              },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }