var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100" }, [
    _vm.selectedTagSuggestionsCategory === "TRANSCRIPT"
      ? _c(
          "div",
          [
            _c("Transcript", {
              attrs: {
                filter: _vm.filter,
                audioUid: _vm.audioUid,
                playSnippet: _vm.playSnippet,
                pauseSnippet: _vm.pauseSnippet,
                currentTime: _vm.currentTime,
                onCurrentTime: _vm.onCurrentTime,
                isMaximized: _vm.isMaximized,
                selectedTagSuggestionsCategory:
                  _vm.selectedTagSuggestionsCategory,
              },
              on: {
                "scroll-timeline": function ($event) {
                  return _vm.onCurrentTime($event)
                },
                "suggestion-category": _vm.handleCategory,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.selectedTagSuggestionsCategory === "EPISODE_INFO"
      ? _c(
          "div",
          [
            _c("EpisodeInfo", {
              attrs: {
                filter: _vm.filter,
                audioUid: _vm.audioUid,
                currentTime: _vm.currentTime,
                onCurrentTime: _vm.onCurrentTime,
                isMaximized: _vm.isMaximized,
                selectedTagSuggestionsCategory:
                  _vm.selectedTagSuggestionsCategory,
              },
              on: { "suggestion-category": _vm.handleCategory },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }