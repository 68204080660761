























































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import SubtitleBlock from '@/components/Subtitle/SubtitleBlock.vue'

// constants
import { downloadFormats } from '@/components/Subtitle/constants'
import { uploadStatus } from '@/components/Publish/publish'

import AdoriService from '@/services/adori'
import { QueryClient, useQueryClient } from 'vue-query'
import { computed, ref, SetupContext } from '@vue/composition-api'
import { transcriptQuery, useGenerateTranscript, useGetTranscript } from '@/hooks/transcript'
import { useGenerateSubtitles } from '@/hooks/subtitle'
import get from 'lodash.get'
import VirtualList from 'vue-virtual-scroll-list'

@Component({
  components: { SubtitleBlock, VirtualList },
  setup(props: any, { root }: SetupContext) {
    const queryClient = useQueryClient()
    const trackUid = props.trackUid
    const transcriptInterval: any = ref(false)
    const isTranscriptExist = computed(() => !!root.$store.getters.audio(trackUid).transcriptId)
    const { data: transcript, isLoading: isTranscriptLoading } = useGetTranscript(
      trackUid,
      {
        enabled: isTranscriptExist,
      },
      transcriptInterval,
      (data: any) => transcriptProcessing(data)
    )
    const transcriptProcessing = (data: any) => {
      if (data) {
        if ([uploadStatus.QUEUED, uploadStatus.STARTED].includes(data.status)) transcriptInterval.value = 10000
        else if ([uploadStatus.FINISHED, uploadStatus.FAILED].includes(data.status)) transcriptInterval.value = false
      }
    }
    const { mutate: generateTranscript, isLoading: isTranscriptGenerating } = useGenerateTranscript()
    const { mutate: generateSubtitles, isLoading: isSubtitleGenerating } = useGenerateSubtitles()
    return {
      queryClient,
      transcriptInterval,
      isTranscriptExist,
      transcriptProcessing,
      transcript,
      isTranscriptLoading,
      generateTranscript,
      generateSubtitles,
      isTranscriptGenerating,
      isSubtitleGenerating,
    }
  },
})
export default class TheSubtitleEditor extends Vue {
  @Prop(Boolean) isMaximized!: boolean
  @Prop(String) trackUid!: string
  @Prop(Number) currentTime!: number

  @Getter languages!: any
  @Getter subtitlesList!: any
  @Getter networkId!: string
  @Getter selectedTemplate!: any
  @Getter getTranscript!: Function
  @Getter ytModalId!: any
  @Getter selectedEpisodeSettings!: any
  @Getter hasUnsavedChanges!: boolean

  @Action saveAudioTrackSubtitle!: Function
  @Action downloadSubtitles!: Function

  timeFormat = 'LONG' // SHORT for ms and LONG for hh:mm:ss.sss

  selectedSubtitleCategory = 'OPTIONS'
  selectedLanguage: string = 'en-US'
  selectedFormat = 'SRT'
  toggleAutoSubtitle = false
  saveText = 'Save changes'
  loading = false
  uploadTranscriptStatus = false
  isDownloading = false

  startMilliSec: number = 0
  endMilliSec: number = 0
  isAudioClipped = false

  queryClient!: QueryClient
  transcriptInterval!: boolean
  isTranscriptExist!: boolean
  transcriptProcessing!: any
  transcript!: any
  isTranscriptLoading!: boolean
  generateTranscript!: any
  generateSubtitles!: any
  isSubtitleGenerating!: any
  isTranscriptGenerating!: any

  get SubtitleBlock() {
    return SubtitleBlock
  }

  get subtitles() {
    if (this.isAudioClipped) {
      return this.subtitlesList.filter((s: any) => s.start >= this.startMilliSec && s.start <= this.endMilliSec)
    } else {
      return this.subtitlesList
    }
  }

  get transcriptStatus() {
    return this.transcript && this.transcript.status
  }

  get subtitleStatusText() {
    if (this.transcriptStatus && !this.isSubtitleGenerating) {
      return [uploadStatus.STARTED, uploadStatus.QUEUED].includes(this.transcriptStatus)
        ? 'Generating Transcript ...'
        : [uploadStatus.FAILED].includes(this.transcriptStatus)
        ? 'Regenerate Subtitles'
        : 'Start'
    }
    return this.buttonDisabled ? 'Generating Subtitles ...' : 'Start'
  }

  get buttonDisabled() {
    return this.isTranscriptGenerating || this.isSubtitleGenerating || !!this.transcriptInterval
  }

  get formats() {
    return downloadFormats
  }

  get selectedStyle() {
    return get(this.selectedTemplate, 'name', 'CUSTOM')
  }

  async mounted() {
    if (this.ytModalId && this.selectedEpisodeSettings[this.ytModalId]) {
      this.startMilliSec = this.selectedEpisodeSettings[this.ytModalId].startTimeSec * 1000
      this.endMilliSec = this.selectedEpisodeSettings[this.ytModalId].endTimeSec * 1000
      this.isAudioClipped = true
    }

    this.transcriptProcessing(this.transcript)
  }

  changeTimeFormat() {
    this.timeFormat == 'LONG' ? (this.timeFormat = 'SHORT') : (this.timeFormat = 'LONG')
  }

  handleStyleTab() {
    this.selectedSubtitleCategory = 'STYLES'
  }
  handleOptionsTab() {
    this.selectedSubtitleCategory = 'OPTIONS'
  }
  addNewSubtitle() {
    this.$store.commit('setUnsavedChanges', true)
    const subtitles = [...this.subtitlesList]
    const subtitlePayload = {
      start: 0,
      end: 3000,
      text: 'New text',
      overrideStyle: {
        template: null,
        effect: null,
        wordLevelStyling: [
          {
            words: ['string', 'string'],
            color: '#ffffff',
            Bold: false,
            italic: false,
            underline: false,
            strikethrough: false,
          },
          {
            words: ['string', 'string'],
            color: '#ffffff',
            Bold: false,
            italic: false,
            underline: false,
            strikethrough: false,
          },
        ],
      },
    }
    // @ts-ignore
    this.$refs.list.scrollToBottom()

    const subtitleListLength = this.subtitlesList.length
    if (this.subtitlesList.length) {
      const temp = this.subtitlesList[subtitleListLength - 1]
      subtitlePayload['start'] = temp.end
      subtitlePayload['end'] = temp.end + 3000
    }
    subtitles.push(subtitlePayload)
    this.$store.commit('updateSubtitle', subtitles)
  }
  removeSubtitleLine(line: any) {
    this.$store.commit('setUnsavedChanges', true)
    const index = this.subtitlesList.indexOf(line)
    const subtitles = [...this.subtitlesList]
    subtitles.splice(index, 1)
    this.$store.commit('updateSubtitle', subtitles)
  }
  addSubtitleInBetween(line: any) {
    this.$store.commit('setUnsavedChanges', true)
    const index = this.subtitlesList.indexOf(line) + 1
    const subtitles = [...this.subtitlesList]
    const subtitlePayload = {
      start: 0,
      end: 3000,
      text: 'New text',
      overrideStyle: {
        template: null,
        effect: null,
        wordLevelStyling: [
          {
            words: ['string', 'string'],
            color: '#ffffff',
            Bold: false,
            italic: false,
            underline: false,
            strikethrough: false,
          },
          {
            words: ['string', 'string'],
            color: '#ffffff',
            Bold: false,
            italic: false,
            underline: false,
            strikethrough: false,
          },
        ],
      },
    }
    subtitlePayload['start'] = subtitles[index - 1].end
    subtitlePayload['end'] = subtitles[index - 1].end + 3000
    subtitles.splice(index, 0, subtitlePayload)
    this.$store.commit('updateSubtitle', subtitles)
  }

  updateSubtitle(data: any) {
    this.$store.commit('setUnsavedChanges', true)
    const subtitles = [...this.subtitlesList]
    subtitles[data.index] = data.source
    this.$store.commit('updateSubtitle', subtitles)
  }

  changeHighlightedWords(data: any) {
    this.$store.commit('setUnsavedChanges', true)
    const index = this.subtitlesList.indexOf(data.line)
    const subtitles = [...this.subtitlesList]
    subtitles[index].overrideStyle.wordLevelStyling[0].words = data.words
    this.$store.commit('updateSubtitle', subtitles)
  }
  changeTextColor(data: any) {
    this.$store.commit('setUnsavedChanges', true)
    const index = this.subtitlesList.indexOf(data.line)
    const subtitles = [...this.subtitlesList]
    subtitles[index].overrideStyle.wordLevelStyling[0].color = data.color
    this.$store.commit('updateSubtitle', subtitles)
  }
  changeText(data: any) {
    this.$store.commit('setUnsavedChanges', true)
    const index = this.subtitlesList.indexOf(data.line)
    const subtitles = [...this.subtitlesList]
    subtitles[index].text = data.text
    this.$store.commit('updateSubtitle', subtitles)
  }

  chooseStyle() {
    this.$store.dispatch('showSubtitleStyle')
  }

  async handleSave() {
    this.$store.commit('setUnsavedChanges', false)
    this.saveText = 'Saving...'
    this.loading = true
    const payload = {
      textBlocks: [...this.subtitlesList],
      subtitleStyleId: this.selectedTemplate.id || null,
      template: this.selectedTemplate.template || this.selectedTemplate.enum || 'BASIC',
      animation: 'NONE',
      isEdited: true,
      params: {
        network_id: this.networkId,
        track_uid: this.trackUid,
      },
    }
    try {
      await this.saveAudioTrackSubtitle(payload)
      this.saveText = 'Save changes'
      this.loading = false
    } catch (error) {
      console.log(error)
      this.loading = false
      this.saveText = 'Save Changes'
    }
  }

  async handleGenerate() {
    if (!this.isTranscriptExist) {
      this.generateTranscript({
        trackUid: this.trackUid,
        language: this.selectedLanguage || 'en-US',
      })
    }
    sessionStorage.setItem('generateSubtitles', 'YES')
    if (this.transcriptStatus === uploadStatus.FINISHED) {
      this.$gtag.event('generate-subtitle', { action: 'automatic subtitle is generated' })
      this.generateSubtitles(this.trackUid)
    }
  }

  handleUpload() {
    this.$store.dispatch('showFileUploader', {
      accept: '*',
      onChange: this.loadTranscriptFile,
      dontCheck: true,
    })
    this.$gtag.event('upload-subtitle', { action: 'uploaded srt file' })
  }

  fileType(file: any) {
    const filename = file.name
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename
  }

  async loadTranscriptFile() {
    const file = this.$store.getters.selectedFile

    if (this.fileType(file) !== 'srt') {
      this.$store.dispatch('pushNotification', {
        text: 'Please upload only SRT files',
        type: 'WARNING',
      })
      return
    }
    this.uploadTranscriptStatus = true
    try {
      if (file) {
        await AdoriService.uploadTranscript(this.$store.getters.networkId, this.trackUid, file)
        this.queryClient.invalidateQueries([transcriptQuery.TRANSCRIPT, this.networkId, this.trackUid])
        this.generateSubtitles(this.trackUid)
        this.uploadTranscriptStatus = false
      }
    } catch (error) {
      this.uploadTranscriptStatus = false
    }
  }

  async handleDownload() {
    this.isDownloading = true
    try {
      const data = await this.downloadSubtitles({
        audioUid: this.trackUid,
        format: this.selectedFormat,
      })
      const link = this.$refs.transcriptDownloadRef as HTMLAnchorElement
      link.href = data.url
      link.click()
    } catch (error) {}
    this.isDownloading = false
  }

  @Watch('transcriptStatus')
  async onTranscriptStatusChanged() {
    if (
      (this.transcriptStatus === uploadStatus.FINISHED || this.uploadTranscriptStatus) &&
      sessionStorage.getItem('generateSubtitles')
    ) {
      localStorage.removeItem('generateSubtitles')
      this.generateSubtitles(this.trackUid)
      this.toggleAutoSubtitle = false
    }
  }

  @Watch('currentTime')
  handleCurrentTime() {
    if (
      this.currentTime >= this.subtitles[0].start / 1000 &&
      this.currentTime <= this.subtitles[this.subtitles.length - 1].start / 1000
    ) {
      // @ts-ignore
      this.$refs.list.$el.scroll(
        0,
        // @ts-ignore
        this.currentTime * (this.$refs.list.getScrollSize() / (this.subtitles[this.subtitles.length - 1].start / 1000))
      )
    }
    // @ts-ignore
    if (this.currentTime < this.subtitles[0].start / 1000) this.$refs.list.$el.scroll(0, 0)
  }
}
