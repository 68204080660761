var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: true },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f3 light-gray fw5 di" }, [
          _vm._v("Choose transcript format"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "flex flex-column mt2 w-100" }, [
          _c("div", { staticClass: "mb2" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.transcriptFormat,
                  expression: "transcriptFormat",
                },
              ],
              staticClass: "mr2",
              attrs: { type: "radio", value: "TEXT", id: "TEXT" },
              domProps: { checked: _vm._q(_vm.transcriptFormat, "TEXT") },
              on: {
                change: function ($event) {
                  _vm.transcriptFormat = "TEXT"
                },
              },
            }),
            _c(
              "label",
              { staticClass: "f6 light-gray pointer", attrs: { for: "TEXT" } },
              [_vm._v("TEXT")]
            ),
          ]),
          _c("div", { staticClass: "mb2" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.transcriptFormat,
                  expression: "transcriptFormat",
                },
              ],
              staticClass: "mr2",
              attrs: { type: "radio", value: "SRT", id: "SRT" },
              domProps: { checked: _vm._q(_vm.transcriptFormat, "SRT") },
              on: {
                change: function ($event) {
                  _vm.transcriptFormat = "SRT"
                },
              },
            }),
            _c(
              "label",
              { staticClass: "f6 light-gray pointer", attrs: { for: "SRT" } },
              [_vm._v("SRT")]
            ),
          ]),
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.transcriptFormat,
                  expression: "transcriptFormat",
                },
              ],
              staticClass: "mr2",
              attrs: { type: "radio", value: "DOCX", id: "DOCX" },
              domProps: { checked: _vm._q(_vm.transcriptFormat, "DOCX") },
              on: {
                change: function ($event) {
                  _vm.transcriptFormat = "DOCX"
                },
              },
            }),
            _c(
              "label",
              { staticClass: "f6 light-gray pointer", attrs: { for: "DOCX" } },
              [_vm._v("DOCX")]
            ),
          ]),
        ]),
        _c(
          "div",
          [
            _c("BaseButtonRed", {
              attrs: {
                text: _vm.downloadButtonText,
                onClick: _vm.handleDownload,
                loading: _vm.transcriptLoader,
                disabled: _vm.transcriptLoader,
              },
            }),
            _c(
              "a",
              {
                ref: "transcriptDownloadRef",
                staticClass: "dn",
                attrs: { download: "" },
              },
              [_vm._v("Download Audio")]
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "footer" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }