var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: _vm.start / 1000,
      staticClass: "subtitles-container",
      class: { active: _vm.isActive },
    },
    [
      _c("div", { staticClass: "subtitle-content" }, [
        _c("div", { staticClass: "row-indicator" }),
        _c("div", { staticClass: "subtitle-time-text-wrapper flex" }, [
          _c("div", { staticClass: "edit-time-container" }, [
            _c("div", { staticClass: "edit-time-wrapper" }, [
              _vm._m(0),
              _c("input", {
                staticClass: "subtitle-time-input",
                attrs: { type: "text", maxlength: _vm.maxTimeLength },
                domProps: {
                  value:
                    _vm.timeFormat == "LONG"
                      ? _vm.secondsToTimeCode(_vm.start / 1000)
                      : _vm.start,
                },
                on: {
                  blur: _vm.startTimeChange,
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.startTimeChange.apply(null, arguments)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "edit-time-wrapper" }, [
              _vm._m(1),
              _c("input", {
                staticClass: "subtitle-time-input",
                attrs: { type: "text", maxlength: _vm.maxTimeLength },
                domProps: {
                  value:
                    _vm.timeFormat == "LONG"
                      ? _vm.secondsToTimeCode(_vm.end / 1000)
                      : _vm.end,
                },
                on: {
                  blur: _vm.endTimeChange,
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.endTimeChange.apply(null, arguments)
                  },
                },
              }),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "subtitle-text-container flex items-center" },
            [
              _c("SubtitleText", {
                attrs: { value: _vm.text, edit: _vm.edit },
                on: { "change-text": _vm.changeText },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "subtitle-tools" }, [
          _c(
            "i",
            {
              staticClass:
                "material-icons edit-subtitle light-gray f5 v-mid pa1",
              on: { click: _vm.editSubtitle },
            },
            [_vm._v(" edit ")]
          ),
          _c(
            "svg",
            {
              staticClass: "add-subtitle",
              attrs: {
                viewBox: "64 64 896 896",
                xmlns: "http://www.w3.org/2000/svg",
                focusable: "false",
                width: "16",
                height: "16",
                fill: "currentColor",
                title: "Add caption below",
              },
              on: { click: _vm.addSubtitle },
            },
            [
              _c("path", {
                attrs: {
                  d: "M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z",
                },
              }),
            ]
          ),
          _c(
            "svg",
            {
              staticClass: "delete-subtitle",
              attrs: {
                width: "16",
                height: "16",
                viewBox: "0 0 16 16",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
                title: "Delete",
              },
              on: { click: _vm.removeSubtitle },
            },
            [
              _c("path", {
                attrs: {
                  d: "M2 4H3.33333H14",
                  stroke: "currentColor",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M5.3335 4.00016V2.66683C5.3335 2.31321 5.47397 1.97407 5.72402 1.72402C5.97407 1.47397 6.31321 1.3335 6.66683 1.3335H9.3335C9.68712 1.3335 10.0263 1.47397 10.2763 1.72402C10.5264 1.97407 10.6668 2.31321 10.6668 2.66683V4.00016M12.6668 4.00016V13.3335C12.6668 13.6871 12.5264 14.0263 12.2763 14.2763C12.0263 14.5264 11.6871 14.6668 11.3335 14.6668H4.66683C4.31321 14.6668 3.97407 14.5264 3.72402 14.2763C3.47397 14.0263 3.3335 13.6871 3.3335 13.3335V4.00016H12.6668Z",
                  stroke: "currentColor",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _c("path", {
                attrs: {
                  opacity: "0.5",
                  d: "M6.6665 7.3335V11.3335",
                  stroke: "currentColor",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _c("path", {
                attrs: {
                  opacity: "0.5",
                  d: "M9.3335 7.3335V11.3335",
                  stroke: "currentColor",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showOverrideStyle,
              expression: "showOverrideStyle",
            },
          ],
          staticClass: "flex ml4",
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.highlightedWords,
                expression: "highlightedWords",
              },
            ],
            staticClass:
              "db bg-adori-very-light-gray br2 ph3 bn gray f6 mb2 white custom-w",
            attrs: {
              type: "text",
              placeholder: "Enter words separated by comma",
            },
            domProps: { value: _vm.highlightedWords },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.highlightedWords = $event.target.value
                },
                function (e) {
                  return _vm.debounceHighlightedWords(e)
                },
              ],
            },
          }),
          _c("div", { attrs: { id: "text-format" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "on-clickaway",
                    rawName: "v-on-clickaway",
                    value: _vm.closeTextColorPicker,
                    expression: "closeTextColorPicker",
                  },
                ],
                attrs: { id: "color-choose" },
                on: { click: _vm.toggleTextColorPicker },
              },
              [
                _c(
                  "div",
                  { staticClass: "relative" },
                  [
                    _c("BaseNewTooltip", { attrs: { text: "Text color" } }, [
                      _c("div", { staticClass: "font-color" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/audiogram/text-color.svg"),
                          },
                        }),
                        _c("div", {
                          staticClass: "mini-swatch",
                          style: "background-color: " + _vm.textColorValue,
                        }),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _vm.displayTextColorPicker
                          ? _c("Sketch", {
                              staticClass: "sketch-picker",
                              attrs: { value: _vm.textColorValue },
                              on: { input: _vm.updateTextColorFromPicker },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "text-format" },
              [
                _c("BaseNewTooltip", { attrs: { text: "Bold" } }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/audiogram/text-format/text-bold.svg"),
                    },
                  }),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "text-format" },
              [
                _c("BaseNewTooltip", { attrs: { text: "Italics" } }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/audiogram/text-format/text-italic.svg"),
                    },
                  }),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "text-format" },
              [
                _c("BaseNewTooltip", { attrs: { text: "Underline" } }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/audiogram/text-format/text-underline.svg"),
                    },
                  }),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "text-format" },
              [
                _c("BaseNewTooltip", { attrs: { text: "Cross out" } }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/audiogram/text-format/text-strikethrough.svg"),
                    },
                  }),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "subtitle-separator" }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "edit-time-stopwatch",
        attrs: { "aria-label": "Set start time" },
      },
      [
        _c("img", {
          attrs: { src: require("@/assets/subtitle/stopwatch.svg") },
        }),
        _c("span", { staticClass: "edit-time-stopwatch-label" }, [
          _vm._v("In"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "edit-time-stopwatch",
        attrs: { "aria-label": "Set end time" },
      },
      [
        _c("img", {
          attrs: { src: require("@/assets/subtitle/stopwatch.svg") },
        }),
        _c("span", { staticClass: "edit-time-stopwatch-label" }, [
          _vm._v("Out"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }