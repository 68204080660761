var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex justify-between items-center" }, [
      _c("p", { staticClass: "f5 fw1 light-gray" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c(
        "div",
        {
          staticClass:
            "ba bw1 br4 b--transparent bg-black-20 flex items-center",
        },
        [
          _c(
            "label",
            {
              staticClass: "f6 pv1 ph2 silver link pointer mr1",
              class:
                _vm.selectedLinkTab === "TAGS_VIEW"
                  ? "ba br4 b--transparent bg-black-80 light-gray"
                  : "",
            },
            [
              _c("input", {
                staticClass: "input-reset",
                attrs: { type: "radio" },
                domProps: { checked: _vm.selectedLinkTab === "TAGS_VIEW" },
                on: {
                  click: function ($event) {
                    _vm.selectedLinkTab = "TAGS_VIEW"
                  },
                },
              }),
              _vm._v("Tags view"),
            ]
          ),
          _c(
            "label",
            {
              staticClass: "f6 pv1 ph2 silver link pointer",
              class:
                _vm.selectedLinkTab === "LINKS_VIEW"
                  ? "ba br4 b--transparent bg-black-80 light-gray"
                  : "",
            },
            [
              _c("input", {
                staticClass: "input-reset",
                attrs: { type: "radio" },
                domProps: { checked: _vm.selectedLinkTab === "LINKS_VIEW" },
                on: {
                  click: function ($event) {
                    _vm.selectedLinkTab = "LINKS_VIEW"
                  },
                },
              }),
              _vm._v("Links view"),
            ]
          ),
        ]
      ),
    ]),
    _vm.selectedLinkTab === "TAGS_VIEW"
      ? _c("div", [
          _vm.linksWithImages.length !== 0
            ? _c(
                "div",
                { staticClass: "flex flex-wrap mt3 justify-center" },
                _vm._l(_vm.linksWithImages, function (link, i) {
                  return _c(
                    "div",
                    {
                      key: "link-tag-" + i,
                      staticClass: "mb4 relative",
                      staticStyle: { "place-self": "center" },
                    },
                    [
                      _c("TagSuggestionsLinkTagPreview", {
                        attrs: {
                          filter: _vm.filter,
                          link: link,
                          audioUid: _vm.audioUid,
                          currentTime: _vm.currentTime,
                          onCurrentTime: _vm.onCurrentTime,
                          hasButtons: "",
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _c("p", { staticClass: "silver" }, [
                _vm._v("No links with images found in these tag suggestions."),
              ]),
        ])
      : _vm._e(),
    _vm.selectedLinkTab === "LINKS_VIEW"
      ? _c("div", [
          _vm.links.length !== 0
            ? _c(
                "ul",
                { staticClass: "mw6 pb3 overflow-x-auto scrollbar" },
                _vm._l(_vm.links, function (link, i) {
                  return _c("li", { key: "link-" + i }, [
                    _c(
                      "a",
                      {
                        staticClass: "lh-copy silver link dim pointer",
                        attrs: { href: link.url, target: "_blank" },
                      },
                      [_vm._v(_vm._s(link.url))]
                    ),
                  ])
                }),
                0
              )
            : _c("p", { staticClass: "silver" }, [
                _vm._v("No links found in these tag suggestions."),
              ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }