var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100 flex" }, [
    _c("div", { staticClass: "w-100" }, [
      _vm.isLoadingSubtitles
        ? _c(
            "div",
            { staticClass: "skeleton-grid" },
            _vm._l(new Array(5), function (_, index) {
              return _c("div", {
                key: index,
                staticClass:
                  "w-100 bg-adori-light-gray bb bw1 b--adori-gray skeleton",
              })
            }),
            0
          )
        : _c(
            "div",
            [
              _c("SubtitleEditor", {
                attrs: {
                  currentTime: _vm.currentTime,
                  isMaximized: _vm.isMaximized,
                  trackUid: _vm.trackUid,
                },
              }),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }