var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "justify-center mt1 silver items-center" }, [
    !_vm.isStepTwo
      ? _c("div", { staticClass: "flex justify-center items-center" })
      : _vm._e(),
    _vm.isStepTwo
      ? _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "flex items-center mb3" }, [
            _vm._m(0),
            _c("div", { staticClass: "flex justify-start f5 icon-s" }, [
              _vm._v(
                "Video Contains " + _vm._s(_vm.tagsInTrack.length) + " Tags"
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "flex items-center mb3 ml3 pointer",
              on: { click: _vm.handleEmbedWeb },
            },
            [
              _vm._m(1),
              _c("div", { staticClass: "flex justify-start f5 icon-s" }, [
                _vm._v("Share Video"),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "canvas-area tc" }, [
      _c("div", { staticClass: "flex flex-column items-center relative" }, [
        _c("canvas", { ref: "canvas" }),
        _c(
          "div",
          {
            staticClass: "flex flex-column ml1 w-100 bg-adori-light-gray",
            style: {
              width:
                (_vm.orientation === "LANDSCAPE"
                  ? 604
                  : _vm.orientation === "SQUARE"
                  ? 340
                  : 228) + "px",
            },
          },
          [
            _c(
              "div",
              { staticClass: "w-100" },
              [
                _c(
                  "vue-slider",
                  _vm._b(
                    {
                      ref: "slider",
                      staticStyle: { padding: "0 !important" },
                      attrs: { min: _vm.startTimeSec, max: _vm.endTimeSec },
                      on: { "drag-end": _vm.dragend, callback: _vm.callback },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function (ref) {
                              var label = ref.label
                              var active = ref.active
                              return _vm.tagLabel.includes(label)
                                ? _c("span", { staticClass: "icon-s" }, [
                                    _vm._v("\n              |\n            "),
                                  ])
                                : _vm._e()
                            },
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.sliderValue,
                        callback: function ($$v) {
                          _vm.sliderValue = $$v
                        },
                        expression: "sliderValue",
                      },
                    },
                    "vue-slider",
                    _vm.options,
                    false
                  )
                ),
              ],
              1
            ),
            _c("div", { staticClass: "flex items-center justify-start mt2" }, [
              _c(
                "i",
                {
                  staticClass: "material-icons light-gray f3 v-mid pa1 pointer",
                  on: { click: _vm.replay },
                },
                [_vm._v("replay")]
              ),
              _c(
                "i",
                {
                  staticClass: "material-icons light-gray f3 v-mid pa1 pointer",
                  on: { click: _vm.togglePlay },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.isPlaying
                        ? "pause_circle_outline"
                        : "play_circle_outline"
                    )
                  ),
                ]
              ),
              _c("div", { staticClass: "flex items-center ml3" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.formattedCurrentTime + " / " + _vm.formattedTotalTime
                    ) +
                    "\n          "
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-bg" }, [
      _c("i", { staticClass: "material-icons light-gray center f7 icon-s" }, [
        _vm._v(" local_offer "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-bg" }, [
      _c("i", { staticClass: "material-icons light-gray center f7 icon-s" }, [
        _vm._v(" share "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }