import { fabric } from 'fabric'
import { gifToSprites } from './gifToSprites'

const [PLAY, PAUSE, STOP]: [number, number, number] = [0, 1, 2]

/**
 * fabricGif "async"
 * Mainly a wrapper for gifToSprite
 * @param {string|File} gif can be a URL, dataURL or an "input File"
 * @param {number} maxWidth Optional, scale to maximum width
 * @param {number} maxHeight Optional, scale to maximum height
 * @param {number} maxDuration Optional, in milliseconds reduce the gif frames to a maximum duration, ex: 2000 for 2 seconds
 * @returns {*} {error} object if any or a 'fabric.image' instance of the gif with new 'play', 'pause', 'stop' methods
 */
export const fabricGif = async (
  gif: string | File,
  maxWidth?: number,
  maxHeight?: number,
  maxDuration?: number
): Promise<{ error?: any }> => {
  const {
    error,
    dataUrl,
    delay = 0,
    frameWidth,
    framesLength,
  } = await gifToSprites(gif, maxWidth, maxHeight, maxDuration)

  if (error) return { error }

  return new Promise<any>((resolve) => {
    fabric.Image.fromURL(dataUrl, (img: any) => {
      const sprite = img.getElement() as HTMLImageElement
      let framesIndex = 0
      let start = performance.now()
      let status: number

      img.width = frameWidth
      img.height = sprite.naturalHeight
      img.mode = 'image'
      img.top = 200
      img.left = 200

      img._render = function (ctx: CanvasRenderingContext2D) {
        if (status === PAUSE || (status === STOP && framesIndex === 0)) return
        const now = performance.now()
        const delta = now - start
        if (delta > delay) {
          start = now
          framesIndex++
        }
        if (framesIndex === framesLength || status === STOP) framesIndex = 0

        ctx.drawImage(
          sprite,
          //@ts-ignore
          frameWidth * framesIndex,
          0,
          //@ts-ignore
          frameWidth,
          sprite.height,
          -this.width / 2,
          -this.height / 2,
          frameWidth,
          sprite.height
        )
      }
      img.play = function () {
        status = PLAY
        this.dirty = true
      }
      img.pause = function () {
        status = PAUSE
        this.dirty = false
      }
      img.stop = function () {
        status = STOP
        this.dirty = false
      }
      img.getStatus = () => ['Playing', 'Paused', 'Stopped'][status]

      img.play()
      resolve(img)
    })
  })
}
