






























import { Vue, Component, Prop } from 'vue-property-decorator'
import Transcript from '@/components/Tags/Suggestions/Transcript.vue'
import EpisodeInfo from '@/components/Tags/Suggestions/EpisodeInfo.vue'

@Component({ components: { Transcript, EpisodeInfo } })
export default class TheTagSuggestions extends Vue {
  @Prop(String) filter!: string
  @Prop(String) audioUid!: string
  @Prop(Function)
  playSnippet!: (offset: number, duration: number, callback: Function) => void
  @Prop(Function) pauseSnippet!: () => void
  @Prop(Number) currentTime!: number
  @Prop(Function) onCurrentTime!: Function
  @Prop(Boolean) isMaximized!: boolean

  selectedTagSuggestionsCategory: any = 'TRANSCRIPT' // 'EPISODE_INFO' or 'TRANSCRIPT'

  created() {
    window.scrollTo(0, 0)
  }

  handleCategory(category: string) {
    this.selectedTagSuggestionsCategory = category
  }
}
