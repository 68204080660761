var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closeMenu,
          expression: "closeMenu",
        },
      ],
      attrs: { tabindex: "0" },
      on: {
        mouseup: function ($event) {
          $event.preventDefault()
          return _vm.onMouseUp.apply(null, arguments)
        },
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeMenu.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showMenu,
              expression: "showMenu",
            },
          ],
          staticClass: "menu",
          style: { left: _vm.x + "px", top: _vm.y + "px" },
          on: {
            mousedown: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "menu-item flex items-center f6",
              on: {
                mousedown: function ($event) {
                  $event.preventDefault()
                  return _vm.handleAction(
                    _vm.isPlayingSnippet ? "pause-snippet" : "play-snippet"
                  )
                },
              },
            },
            [
              !_vm.isPlayingSnippet
                ? _c("i", { staticClass: "f4 mr2 material-icons dark-gray" }, [
                    _vm._v("play_circle_filled"),
                  ])
                : _c("i", { staticClass: "f4 mr2 material-icons dark-gray" }, [
                    _vm._v("pause_circle_filled"),
                  ]),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.isPlayingSnippet ? "Pause snippet" : "Play snippet"
                  ) +
                  "\n    "
              ),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "menu-item flex items-center f6",
              on: {
                mousedown: function ($event) {
                  $event.preventDefault()
                  return _vm.handleAction("create-tag")
                },
              },
            },
            [
              _c("i", { staticClass: "f4 mr2 material-icons dark-gray" }, [
                _vm._v("create"),
              ]),
              _vm._v("\n      Create Visuals\n    "),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "menu-item flex items-center f6",
              on: {
                mousedown: function ($event) {
                  $event.preventDefault()
                  return _vm.handleAction("search-google")
                },
              },
            },
            [
              _c("i", { staticClass: "f4 mr2 material-icons dark-gray" }, [
                _vm._v("search"),
              ]),
              _vm._v("\n      Search Google\n    "),
            ]
          ),
        ]
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }