

















import { Vue, Component, Prop } from 'vue-property-decorator'

import SubtitleEditor from '@/components/Subtitle/SubtitleEditor.vue'
import SubtitlePreview from '@/components/Subtitle/SubtitlePreview.vue'
import { useGetSubtitles, useGetSubtitleTemplates } from '@/hooks/subtitle'
import { useGetLanguages } from '@/hooks/transcript'

@Component({
  components: { SubtitleEditor, SubtitlePreview },
  setup(props: any) {
    useGetSubtitleTemplates()
    useGetLanguages()
    const { isLoading: isLoadingSubtitles } = useGetSubtitles(props.trackUid)

    return {
      isLoadingSubtitles,
    }
  },
})
export default class TheSubtitlePanel extends Vue {
  @Prop(Boolean) isMaximized!: boolean
  @Prop(Number) currentTime!: number
  @Prop(String) trackUid!: string
  @Prop(Boolean) isPlaying!: boolean
  @Prop(Array) tagsInTrack!: any[]
  @Prop(String) coverImage!: string

  isLoadingSubtitles!: boolean

  togglePlay(playPause: boolean) {
    this.$emit('toggle-play', playPause)
  }
  scrollTimeline(value: number) {
    this.$emit('scroll-timeline', value)
  }
}
