var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.timelineLoader || _vm.isTimeTrackTimelineLoader,
              expression: "timelineLoader || isTimeTrackTimelineLoader",
            },
          ],
          staticClass: "relative",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-100 absolute z-4 custom-style bg-black-40 flex flex-column justify-center items-center",
            },
            [
              _c("div", { staticClass: "loader" }),
              _c("div", { staticClass: "white mt3 f6" }, [
                _vm._v(
                  _vm._s(_vm.showLoaderText ? _vm.showLoaderText : "Adding Tag")
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "draggable",
        {
          staticClass: "overflow-x-scroll relative scroll unselectable",
          attrs: {
            id: "audioTaggerEl",
            options: _vm.dragOptions,
            move: _vm.onMove,
          },
          on: {
            start: function ($event) {
              return _vm.$emit("drag-start")
            },
            end: _vm.handleDroppedTag,
          },
          model: {
            value: _vm.tagIds,
            callback: function ($$v) {
              _vm.tagIds = $$v
            },
            expression: "tagIds",
          },
        },
        [
          _c(
            "div",
            {
              on: {
                dragover: function ($event) {
                  $event.preventDefault()
                },
                dblclick: _vm.createTag,
                drop: function ($event) {
                  $event.preventDefault()
                  return _vm.dropFile($event)
                },
              },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "m-left",
                  attrs: { width: _vm.width, height: _vm.height },
                },
                [
                  _vm._l(_vm.verticalLinePoints, function (point) {
                    return _c("g", { key: point }, [
                      _c("line", {
                        staticStyle: {
                          stroke: "rgb(63, 63, 63)",
                          "stroke-width": "2",
                        },
                        attrs: {
                          x1: point,
                          x2: point,
                          y1: _vm.timestampHeight,
                          y2: _vm.height,
                        },
                      }),
                      _c(
                        "text",
                        {
                          staticClass: "f7 code",
                          attrs: {
                            x: point - 17,
                            y: "15",
                            fill: "rgb(128, 128, 128)",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                [0, 40].includes(point)
                                  ? ""
                                  : _vm.getTime(point)
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ])
                  }),
                  _c("line", {
                    staticStyle: {
                      stroke: "rgb(200, 200, 200)",
                      "stroke-width": "2",
                    },
                    attrs: {
                      y1: _vm.height / 2 + 10,
                      y2: _vm.height / 2 + 10,
                      x1: "0",
                      x2: _vm.width,
                    },
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "absolute custom-text-position" }, [
                _c("span", { staticClass: "f7 code" }, [_vm._v("00:00")]),
              ]),
              _vm.$env.windowOrigin() !== _vm.$env.envConstProd()
                ? _c(
                    "div",
                    _vm._l(_vm.audioAdsByAudio, function (item) {
                      return _c(
                        "svg",
                        {
                          key: "svg" + item.ad.id,
                          staticClass: "absolute m-left",
                          style: {
                            top: "19px",
                            left:
                              _vm.getAdTimelinePositions(
                                item.audioMarkInMillis
                              ) + "px",
                          },
                          attrs: {
                            width: _vm.getAdTimelinePositions(
                              item.ad.durationMillis
                            ),
                            height: "185",
                          },
                        },
                        [
                          _c("rect", {
                            staticStyle: {
                              stroke: "rgb(200, 200, 200)",
                              "stroke-width": "1",
                            },
                            attrs: {
                              height: "15",
                              width: _vm.getAdTimelinePositions(
                                item.ad.durationMillis
                              ),
                              fill: "rgba(255, 0, 0,0.2)",
                            },
                          }),
                          _c(
                            "text",
                            {
                              staticClass: "f7 white",
                              attrs: {
                                x: "7",
                                y: "12",
                                fill: "rgb(255, 255, 255)",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    " < Ad > : " +
                                      (item.ad.name.length > 15
                                        ? item.ad.name.slice(0, 15) + "..."
                                        : item.ad.name)
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _c(
                            "text",
                            {
                              staticClass: "f7 white",
                              attrs: {
                                x:
                                  _vm.getAdTimelinePositions(
                                    item.ad.durationMillis
                                  ) - 40,
                                y: "12",
                                fill: "rgb(255, 255, 255)",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(" < Ad > ") +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._l(_vm.audiogramXOffsets, function (item, index) {
                return _c("div", { key: item.id }, [
                  _c(
                    "svg",
                    {
                      staticClass:
                        "m-left absolute left-0 right-0 top-0 bottom",
                      attrs: { width: _vm.width, height: _vm.height },
                    },
                    [
                      _c("g", [
                        _c("line", {
                          staticStyle: {
                            stroke: "#4ddf14",
                            "stroke-width": "2",
                          },
                          attrs: {
                            y1: _vm.height / 2 + 10,
                            y2: _vm.height / 2 + 10,
                            x1: item.offset,
                            x2:
                              item.offset +
                              _vm.durationOffset(
                                item,
                                _vm.audiogramXOffsets[index + 1]
                              ),
                          },
                        }),
                      ]),
                    ]
                  ),
                ])
              }),
              _vm._l(_vm.tagXOffsets, function (item, index) {
                return _c("div", { key: item.id }, [
                  _c(
                    "svg",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.action && item.action === "audio",
                          expression: "item.action && item.action === 'audio'",
                        },
                      ],
                      staticClass:
                        "m-left absolute left-0 right-0 top-0 bottom",
                      attrs: { width: _vm.width, height: _vm.height },
                    },
                    [
                      _c("g", [
                        _c("line", {
                          style: _vm.audioAdType(item.tagId)
                            ? "stroke:rgb(52, 173, 74);stroke-width:2"
                            : "stroke:rgb(255,0,0);stroke-width:2",
                          attrs: {
                            x1: item.offset + 90,
                            x2: item.offset + 90,
                            y1: _vm.timestampHeight,
                            y2: _vm.height,
                          },
                        }),
                      ]),
                    ]
                  ),
                  (_vm.ytModalId && _vm.startTimeSec
                    ? _vm.startTimeSec * 1000
                    : 0) <= item.offsetMillis
                    ? _c("div", { attrs: { title: "Tag duration" } }, [
                        _c(
                          "svg",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.action && item.action !== "audio",
                                expression:
                                  "item.action && item.action !== 'audio'",
                              },
                            ],
                            staticClass: "absolute left-0 right-0 top-0 bottom",
                            class:
                              item.offsetMillis === 0 ? "m-0-right" : "m-left",
                            attrs: { width: _vm.width, height: _vm.height },
                          },
                          [
                            _c("g", [
                              _c("line", {
                                staticStyle: {
                                  stroke: "#de1a23",
                                  "stroke-width": "2",
                                },
                                attrs: {
                                  y1: _vm.height / 2 + 10,
                                  y2: _vm.height / 2 + 10,
                                  x1: item.offset + 90,
                                  x2:
                                    item.offset +
                                    90 +
                                    _vm.durationOffset(
                                      item,
                                      _vm.tagXOffsets[index + 1]
                                    ),
                                },
                              }),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ])
              }),
              _c("div", { staticClass: "absolute custom-line-position" }, [
                _c("svg", { attrs: { width: _vm.width, height: _vm.height } }, [
                  _c("line", {
                    directives: [
                      {
                        name: "touch",
                        rawName: "v-touch:start",
                        value: _vm.moveTimelineBar,
                        expression: "moveTimelineBar",
                        arg: "start",
                      },
                    ],
                    staticClass: "moveMe",
                    attrs: {
                      y1: 20,
                      y2: _vm.height,
                      x1: _vm.currentPosition,
                      x2: _vm.currentPosition,
                    },
                    on: { mousedown: _vm.moveTimelineBar },
                  }),
                ]),
              ]),
            ],
            2
          ),
          _vm._l(_vm.tagXOffsets, function (item, index) {
            return _c(
              "div",
              {
                key: "tag" + item.offset,
                class: { "hide-child": _vm.hover },
                attrs: { "data-key": item.id },
              },
              [
                (_vm.ytModalId && _vm.startTimeSec
                  ? _vm.startTimeSec * 1000
                  : 0) <= item.offsetMillis &&
                (item.offsetMillis / 1000) * 40 < _vm.width
                  ? _c(
                      "div",
                      [
                        item.action && item.action === "audio"
                          ? _c("AdoriTag", {
                              staticClass: "absolute smaller",
                              class: {
                                "drag-handle grab":
                                  _vm.isInsertTagEpisodeAllowed,
                                "m-left": item.offsetMillis !== 0,
                                "m-0-right": item.offsetMillis === 0,
                              },
                              style: _vm.getTagStyleSmall(item.offset),
                              attrs: {
                                tagId: item.tagId,
                                tagInTrack: item.tag,
                                draggable: _vm.isInsertTagEpisodeAllowed,
                                ismiliallowed: true,
                                millis: item.millis,
                                duration: item.durationMillis,
                                updateMillis: _vm.updateMillis,
                                index: index,
                                audioUid: _vm.audioUid,
                                filter: undefined,
                              },
                              on: { modalEdit: _vm.toggleHover },
                            })
                          : _c("AdoriTag", {
                              staticClass: "absolute smaller tag-position",
                              class: {
                                "drag-handle grab":
                                  _vm.isInsertTagEpisodeAllowed,
                                "m-left": item.offsetMillis !== 0,
                                "m-0-right": item.offsetMillis === 0,
                              },
                              style: _vm.getTagStyle(
                                item.offset,
                                item.index,
                                item.orientation
                              ),
                              attrs: {
                                tagId: item.tagId,
                                tagInTrack: item.tag,
                                draggable: _vm.isInsertTagEpisodeAllowed,
                                audioUid: _vm.audioUid,
                                duration: item.durationMillis,
                                filter: item.orientation,
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$permissions.isDeleteTagShowAllowed("") && _vm.hover
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "absolute mt1 link pointer ba b--adori-gray br-100 bg-dark-gray child z-2",
                        class: item.offsetMillis === 0 ? "m-0-right" : "m-left",
                        style: {
                          top: 65 + "px",
                          left: item.offset + 105 + "px",
                        },
                        attrs: { title: "Remove tag" },
                        on: {
                          click: function ($event) {
                            return _vm.removeTag(item)
                          },
                        },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass:
                              "material-icons light-gray f7 v-mid pa1",
                          },
                          [_vm._v("clear")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.isEditTagInShowsAllowed(item.tagId) && _vm.hover
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "absolute mt1 link pointer ba b--adori-gray br-100 bg-dark-gray child z-2",
                        class: item.offsetMillis === 0 ? "m-0-right" : "m-left",
                        style: {
                          top: 100 + "px",
                          left: item.offset + 105 + "px",
                        },
                        attrs: { title: "Edit tag" },
                        on: {
                          click: function ($event) {
                            return _vm.editTag(item.tagId, item.orientation)
                          },
                        },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass:
                              "material-icons light-gray f7 v-mid pa1",
                          },
                          [_vm._v("edit")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.isEditDurationAllowed(item.tagId) && _vm.hover
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "absolute mt1 link pointer ba b--adori-gray br-100 bg-dark-gray child z-2",
                        class: item.offsetMillis === 0 ? "m-0-right" : "m-left",
                        style: {
                          top: 140 + "px",
                          left: item.offset + 105 + "px",
                        },
                        attrs: { title: "Edit Tag Properties" },
                        on: {
                          click: function ($event) {
                            return _vm.editTagProperties(index)
                          },
                        },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass:
                              "material-icons light-gray f7 v-mid pa1",
                          },
                          [_vm._v("auto_awesome")]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          }),
          _vm.showTagProperties
            ? _c("TagProperties", {
                attrs: {
                  openTagProperties: _vm.editTagProperties,
                  audioUid: _vm.audioUid,
                  index: _vm.currentItemIndex,
                  tagPositions: _vm.tagXOffsets,
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }