





















import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { mixin as clickaway } from 'vue-clickaway'

@Component
export default class TagSuggestionsEpisodeSelectionMenu extends Mixins(clickaway) {
  @Prop(HTMLElement) selectedNode!: HTMLElement

  x: number = 0
  y: number = 0
  showMenu: boolean = false
  selectedText: string = ''

  get selectableElements() {
    // @ts-ignore
    return this.$slots.default.map((slot: any) => slot.elm)
  }

  closeMenu() {
    this.showMenu = false
  }

  onMouseUp() {
    const selection: any = window.getSelection()
    const selectionRange = selection.getRangeAt(0)

    const startNode = selectionRange.startContainer.parentNode as HTMLElement
    const endNode = selectionRange.endContainer.parentNode as HTMLElement

    if (
      startNode &&
      endNode &&
      this.selectableElements &&
      (this.selectableElements.reduce((acc, elm) => startNode.isSameNode(elm) || false, false) ||
        this.selectableElements.reduce((acc, elm) => endNode.isSameNode(elm) || false, false))
    ) {
      this.showMenu = false
      return
    }

    const { x, y, width } = selectionRange.getBoundingClientRect() as DOMRect

    if (width === 0) {
      this.showMenu = false
      return
    }

    this.x = x + width / 2
    this.y = y + window.scrollY - 10
    this.selectedText = selection.toString()
    this.showMenu = true

    this.scrollToCenter()
  }

  handleAction(action: string) {
    this.$emit(action, this.selectedText)
    this.closeMenu()
  }

  @Watch('selectedNode')
  onSelectedNodeChanged() {
    const { x, y, width } = this.selectedNode.getBoundingClientRect() as DOMRect

    if (width === 0) {
      this.showMenu = false
      return
    }

    this.x = x + width / 2
    this.y = y + window.scrollY - 10
    this.selectedText = this.selectedNode.textContent || ''
    this.showMenu = true

    // this.scrollToCenter()
  }

  scrollToCenter() {
    window.scrollTo({
      top: this.y - window.innerHeight / 2,
      behavior: 'smooth',
    })
  }
}
