var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb3" },
    [
      _c(
        "BaseAdoriTagImage",
        {
          staticClass: "shadow-5 hide-child relative smaller",
          class: _vm.draggable ? "grow grow-small" : "",
          attrs: { src: _vm.tagImageSrc, title: "Suggested" },
        },
        [
          _vm.tag
            ? _c(
                "div",
                { staticClass: "w-100 h-100" },
                [
                  _vm.tag.caption
                    ? _c(
                        "div",
                        {
                          staticClass: "relative w-100",
                          style: { height: _vm.captionAreaHeight + "px" },
                        },
                        [
                          _c("BaseAdoriTagCaption", {
                            attrs: {
                              caption: _vm.tag.caption,
                              fontClass: _vm.fontClass,
                              topMargin: _vm.tag.style.topMarginPercentage,
                              centered: _vm.tagType === "message",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasButtons
                    ? _c("BaseAdoriTagButtons", {
                        attrs: {
                          tagType: _vm.tagType,
                          shareable: _vm.tag.shareable,
                          saveable: _vm.tag.saveable,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.link.offsetMillis && _vm.isInsertTagEpisodeAllowed
        ? _c(
            "span",
            {
              staticClass: "flex justify-between items-center offset-size mt2",
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedOffsetTime,
                      expression: "selectedOffsetTime",
                    },
                  ],
                  staticClass:
                    "input-reset f6 light-gray bn br2 ph2 pv0 shadow-2 db arrow h2 mr2 w-100",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedOffsetTime = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.link.offsetMillis, function (offset) {
                  return _c(
                    "option",
                    { key: offset, domProps: { value: offset } },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.toHms(offset)) + "\n      "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "flex flex-column justify-around absolute",
          staticStyle: { top: "10px", right: "30px" },
        },
        [
          _c(
            "span",
            {
              staticClass:
                "link pointer ba b--adori-gray br-100 bg-dark-gray w2 h2 flex justify-center items-center btn-shadow mb2 grow-small",
              attrs: { title: "Add to current time" },
              on: {
                click: function ($event) {
                  _vm.link.offsetMillis && _vm.isInsertTagEpisodeAllowed
                    ? _vm.handleAddTagSuggestionToTimeline(
                        _vm.selectedOffsetTime
                      )
                    : _vm.handleAddTagSuggestionToTimeline(
                        (_vm.currentTime * 1000) | 0
                      )
                },
              },
            },
            [
              _c(
                "i",
                { staticClass: "material-icons light-gray f4 v-top pa1" },
                [_vm._v(" add ")]
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "light-gray absolute f7 bg-adori-gray pa1 br2",
          staticStyle: { top: "-18px", left: "-5px" },
        },
        [_vm._v("Suggested")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }