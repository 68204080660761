









































































































































































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Sketch } from 'vue-color'
import { mixin as clickaway } from 'vue-clickaway'
import debounce from 'lodash.debounce'

import SubtitleText from '@/components/Subtitle/SubtitleText.vue'
import { removeMsFormatting } from '@/utils/time'

@Component({ components: { Sketch, SubtitleText } })
export default class SubtitleBlock extends Mixins(clickaway) {
  @Prop(Boolean) isMaximized!: boolean
  @Prop(String) trackUid!: string
  @Prop() index!: number
  @Prop(Number) currentTime!: number

  @Prop(Object) source!: any
  @Prop(String) timeFormat!: string

  get start() {
    return this.source.start
  }

  get end() {
    return this.source.end
  }

  get text() {
    return this.source.text
  }

  selectedSubtitleCategory = 'SUBTITLES'
  showOverrideStyle = false
  displayTextColorPicker: boolean = false

  textColorValue = '#FFFFFF'
  highlightedWords = ''
  isActive = false
  edit = false

  @Watch('currentTime')
  handleCurrentTime() {
    if (this.currentTime > this.start / 1000 && this.currentTime < this.end / 1000) {
      // @ts-ignore
      this.$parent.$parent.scrollToIndex(this.index)

      this.isActive = true
    } else {
      this.isActive = false
    }
  }

  created() {
    this.highlightedWords = this.arrayTokeywordString(this.source.overrideStyle.wordLevelStyling[0].words)
    this.textColorValue = this.source.overrideStyle.wordLevelStyling[0].color
  }

  get maxTimeLength() {
    return this.audio?.durationMillis >= 3600000 ? 12 : 9
  }

  get audio() {
    return this.$store.getters.audio(this.trackUid)
  }

  secondsToTimeCode(timeInSeconds: any) {
    const zeropad = (number: number) => {
      return number <= 9 ? `0${number}` : number
    }
    const hours = Math.floor(timeInSeconds / 3600)
    const minutes = Math.floor((timeInSeconds - hours * 3600) / 60) % 60
    const seconds = Math.floor(timeInSeconds % 60)
    const milliSeconds = Math.round(((timeInSeconds % 60) - Math.floor(timeInSeconds % 60)) * 1000)
    //strip leading zeros of hours if not present
    return zeropad(hours) === '00'
      ? `${zeropad(minutes)}:${zeropad(seconds)}.${zeropad(milliSeconds)}`
      : `${zeropad(hours)}:${zeropad(minutes)}:${zeropad(seconds)}.${zeropad(milliSeconds)}`
  }

  arrayTokeywordString(array: any[]) {
    return array.join()
  }
  keywordStringToArray(keywordString: string) {
    if (keywordString === '') return []
    return keywordString.split(',').map((keyword: string) => keyword.trim())
  }

  changeText(text: string) {
    const data = {
      line: this.source,
      text: text,
    }
    this.$parent.$parent.$emit('change-text', data)
  }

  handleSubtitleTab() {
    this.selectedSubtitleCategory = 'SUBTITLES'
  }
  handleStyleTab() {
    this.selectedSubtitleCategory = 'STYLES'
  }
  handleOptionsTab() {
    this.selectedSubtitleCategory = 'OPTIONS'
  }

  removeSubtitle() {
    this.$parent.$parent.$emit('remove-subtitle', this.source)
  }
  addSubtitle() {
    this.$parent.$parent.$emit('add-subtitle', this.source)
  }
  editSubtitle() {
    this.edit = true
  }
  updateTextColorFromPicker(color: any) {
    if (color.rgba.a == 1) {
      this.textColorValue = color.hex
    } else {
      this.textColorValue =
        'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')'
    }
    this.onTextColorChange(this.textColorValue)
  }
  toggleTextColorPicker() {
    this.displayTextColorPicker = !this.displayTextColorPicker
  }
  closeTextColorPicker() {
    this.displayTextColorPicker = false
  }

  onTextColorChange(color: any) {
    const data = {
      line: this.source,
      color: color,
    }
    this.$parent.$parent.$emit('change-text-color', data)
  }

  debounceHighlightedWords = debounce((e: any) => {
    this.changeHighlightedWords(e)
  }, 600)

  changeHighlightedWords(e: any) {
    const data = {
      line: this.source,
      words: this.keywordStringToArray(e.target.value),
    }
    this.$parent.$parent.$emit('change-highlighted-words', data)
  }

  startTimeChange(e: any) {
    this.$parent.$parent.$emit('update-subtitle', {
      index: this.index,
      source: {
        ...this.source,
        start: this.timeFormat == 'LONG' ? removeMsFormatting(e.target.value) : e.target.value,
      },
    })
  }

  endTimeChange(e: any) {
    this.$parent.$parent.$emit('update-subtitle', {
      index: this.index,
      source: { ...this.source, end: this.timeFormat == 'LONG' ? removeMsFormatting(e.target.value) : e.target.value },
    })
  }
}
