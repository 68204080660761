

























































































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import AdoriService from '@/services/adori'
import ImagesList from '@/components/Common/Images/ImagesList.vue'
import TagSuggestionsLinks from '@/components/Tags/Suggestions/TagSuggestionsLinks.vue'
import LazyLoader from '@/mixins/LazyLoader'
import debounce from 'lodash.debounce'
import get from 'lodash.get'
import AdoriTag from '@/components/Tags/AdoriTag.vue'

// constants
import { formatOpenAiCaption, formatOpenAiPoll } from '@/utils/misc'
import { searchImageTypes, imageTypes } from './Suggestions'

@Component({
  components: {
    ImagesList,
    TagSuggestionsLinks,
    AdoriTag,
  },
})
export default class TagSuggestionsSearchImages extends Mixins(LazyLoader) {
  @Prop(String) filter!: string
  @Prop(String) searchText!: string
  @Prop(String) paragraph!: string
  @Prop(String) audioUid!: string
  @Prop(Function) onCurrentTime!: Function
  @Prop(Boolean) isMaximized!: boolean
  @Prop() paragraphTimeStamp!: any
  @Prop() openAiOption!: any
  @Prop() imageSearchText!: any

  // for suggested links
  @Prop(String) tabType!: string
  @Prop(Number) currentTime!: number
  @Prop() suggestedURLs!: any
  @Prop() detectedURLs!: any

  @Getter imageSearchResult!: any
  @Getter isLoadingUserImages!: boolean
  @Getter userId!: string
  @Getter networkId!: string
  @Getter openAiResults!: any
  @Getter openAiPayload!: any

  @Action showTagSuggestion!: Function
  @Action getImages!: Function
  @Action setIsLoadingUserImages!: Function
  @Action getSearchImages!: Function
  @Action setShowLoader!: any
  @Action getTagIds!: any
  @Action openAi!: any

  selectedImageTab: string = 'UNSPLASH' // or 'MY_LIBRARY'

  previewImageSrc: string = ''
  userImageStartIndex: number = 0
  userImageCountPerPage: number = 15
  selectedImage: any = {}

  searchQuery: string = ''
  isFetchingImages: boolean = false
  hasSearched: boolean = false
  invisibleSearchText: any = ''

  currentPoll: any = {}
  currentCaption = ''
  tags: any = []

  openAiPrompt = ''

  @Watch('imageSources')
  async handleImageSources() {
    this.tags = []
    this.imageSources.map(async (imageSource: any, index: number) => {
      const aspectRatio = await this.getImgMeta(imageSource.previewUrl)
      const tag: any = {
        id: index,
        image: {
          url: imageSource.url,
          thumbnailURL: imageSource.previewUrl,
          urls: {
            full: imageSource.previewUrl,
            regular: imageSource.previewUrl,
            small: imageSource.previewUrl,
            thumb: imageSource.previewUrl,
          },
        },
        orientation: this.getOrientation(aspectRatio),
      }

      if (this.openAiOption === 'poll') {
        tag['actions'] = 'choose'
        tag['poll'] = this.currentPoll
      }
      if (this.openAiOption === 'text') {
        tag['actions'] = 'create_note'
        tag['notetext'] = this.currentCaption
        tag['style'] = {
          fontStyle: 1,
          imageOpacity: 1,
          topMarginPercentage: 1,
        }
      }
      this.tags.push(tag)
    })
  }

  created() {
    this.setLazyLoaderDefaults()
  }

  setLazyLoaderDefaults() {
    this.increment = 10
  }

  async mounted() {
    await this.getImages({ limit: this.increment, offset: 0 })
    this.$store.commit('clearSearchImages', imageTypes.UNSPLASH)
    this.$store.commit('clearSearchImages', imageTypes.PIXABAY)
    this.$store.commit('clearSearchImages', imageTypes.GETTY)
    this.$store.commit('clearSearchImages', imageTypes.GOOGLE)
    if (this.invisibleSearchText) {
      this.selectedImageTab = 'UNSPLASH'
      this.resetAndSearchUnsplash()
    }
  }

  beforeDestroy() {
    this.$store.commit('clearSearchImages', imageTypes.UNSPLASH)
    this.$store.commit('clearSearchImages', imageTypes.PIXABAY)
    this.$store.commit('clearSearchImages', imageTypes.GETTY)
    this.$store.commit('clearSearchImages', imageTypes.GOOGLE)
  }

  get searchLibraries() {
    return searchImageTypes
  }

  @Watch('selectedImageTab')
  onSelectImageChange(newVal: string, oldVal: string) {
    this.hasSearched = false
    this.searchImages()
    this.resetIterations()
  }

  @Watch('searchText')
  handleSearch() {
    this.searchQuery = this.searchText
    this.resetAndSearchUnsplash()
  }
  @Watch('imageSearchText')
  handleImgSearch() {
    this.invisibleSearchText = this.imageSearchText
  }

  @Watch('invisibleSearchText')
  handleInvisibleSearch() {
    this.resetAndSearchUnsplash()
  }

  @Watch('openAiOption')
  @Watch('paragraph')
  async handleParagraph() {
    if (this.paragraph) {
      this.isFetchingImages = true
      const payload = {
        // ...this.openAiPayload[this.openAiOption],
        topic: `write an image prompt for this description:${this.paragraph}. Give only prompt as response.`,
        context: this.openAiOption,
      }
      await this.openAi(payload)
      let result = this.openAiResults.result
      this.searchQuery = ''
      this.invisibleSearchText = this.formatOpenAiResult(result, this.openAiOption)
      this.$emit('search', this.formatOpenAiResult(result, this.openAiOption))
    }
  }

  formatOpenAiResult(result: string, option: string) {
    if (['image', 'text', 'custom'].includes(option)) {
      this.currentCaption = formatOpenAiCaption(result)
      return this.currentCaption
    }
    if (option === 'poll') {
      this.currentPoll = formatOpenAiPoll(result)
      return this.currentPoll.topic + '?'
    }
  }

  async customOpenAiSearch() {
    this.isFetchingImages = true
    const payload = {
      //   ...this.openAiPayload[this.openAiOption],
      //   topic: this.paragraph,
      context: this.openAiOption,
      topic: `write an image prompt for this description:${this.openAiPrompt}. Give only prompt as response.`,
    }
    await this.openAi(payload)
    let result = this.openAiResults.result
    this.searchQuery = ''
    this.invisibleSearchText = this.formatOpenAiResult(result, this.openAiOption)
  }

  // +++++++++++++++++++++++++++++++++++++++++

  hasItemsLeftLoad(nav: string) {
    this.setIsLoadingUserImages(true)
    if (nav === 'next') this.lazyLoadDirection = 'DOWN'
    else if (nav === 'prev') this.lazyLoadDirection = 'UP'

    if (this.selectedImageTab === 'MY_LIBRARY') {
      this.handleLoadMore(() => this.imageSearchResult[this.selectedImageTab.toLowerCase()], this.getImages, {
        limit: this.increment,
        offset: get(this.imageSearchResult[this.selectedImageTab.toLowerCase()], 'data', []).length,
      })
    } else {
      // Limit cannot be more than 30 for unsplash
      this.handleLoadMore(() => this.imageSearchResult[this.selectedImageTab.toLowerCase()], this.getSearchImages, {
        query: this.searchQuery || this.invisibleSearchText,
        limit: this.increment,
        offset: get(this.imageSearchResult[this.selectedImageTab.toLowerCase()], 'data', []).length,
        searchLibrary: this.selectedImageTab.toLowerCase(),
      })
    }
    this.setIsLoadingUserImages(false)
  }

  get allImages() {
    return this.getItemsLazy(() => get(this.imageSearchResult[this.selectedImageTab.toLowerCase()], 'data', [])).map(
      (img: any) =>
        this.selectedImageTab === 'MY_LIBRARY'
          ? {
              url: img.url,
              previewUrl: img.thumbnailURL,
              source: 'adori',
              id: img.id,
            }
          : {
              url: img.urls.full,
              previewUrl: img.urls.thumb,
              source: 'unsplash',
              id: img.id,
              user: img.user || null,
            }
    )
  }

  async searchImages() {
    this.hasSearched = true
    this.isFetchingImages = true
    if (!['MY_LIBRARY', 'LINKS'].includes(this.selectedImageTab)) {
      await this.getSearchImages({
        query: this.searchQuery || this.invisibleSearchText,
        limit: this.increment,
        searchLibrary: this.selectedImageTab.toLowerCase(),
        offset: 0,
      })
    }
    this.isFetchingImages = false
  }

  async clearSearchQuery() {
    this.searchQuery = ''
  }

  clearOpenAiPrompt() {
    this.openAiPrompt = ''
  }

  get selectedRangeEnd() {
    const rangeEnd = this.allImages.length
    return rangeEnd
  }

  get selectedRangeStart() {
    return this.selectedRangeEnd === this.totalImages
      ? this.selectedRangeEnd % 10
        ? this.selectedRangeEnd - (this.selectedRangeEnd % 10) + 1
        : this.selectedRangeEnd - (this.selectedRangeEnd % 10) - 9
      : this.selectedRangeEnd - (this.selectedRangeEnd % 10) - 9
  }

  get imgData() {
    return {
      urls: {
        small: this.selectedImage.url,
      },
    }
  }

  get totalImages() {
    const totalImages = get(this.imageSearchResult[this.selectedImageTab.toLowerCase()], 'count', 0) || 0

    return totalImages
  }

  get imageSources() {
    if (this.allImages.length > 10) return this.allImages.slice(this.selectedRangeStart - 1, this.selectedRangeEnd)
    return this.allImages
  }

  async handleSelectImage(result: any) {}

  async resetAndSearchUnsplash() {
    this.searchImages()
  }

  debouncedSearch = debounce(() => {
    this.searchImages()
  }, 600)

  getImgMeta(src: string) {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve(img.width / img.height)
      img.onerror = reject
      img.src = src
    })
  }

  getOrientation(ratio: any) {
    if (ratio > 1.4) return 'LANDSCAPE'
    else if (ratio < 0.8) return 'PORTRAIT'
    else if (ratio >= 0.8 && ratio <= 1.4) return 'SQUARE'
    else return 'LANDSCAPE'
  }

  async onCreateTagFromUnsplash(result: any) {
    this.setShowLoader(true)
    try {
      const imageUrl = result.url

      const response: any = await AdoriService.uploadImage(this.networkId, {
        url: imageUrl,
      })

      const aspectRatio = await this.getImgMeta(response.originalUrl)

      let tag: any = {
        userId: this.userId,
        imageId: response.id,
        shareable: false,
        saveable: false,
        style: {
          fontStyle: 1,
          topMarginPercentage: 1,
          imageOpacity: 0,
        },
        orientation: this.getOrientation(aspectRatio),
      }
      if (['image', 'text'].includes(this.openAiOption)) {
        tag['actions'] = 'click'
        if (this.openAiOption === 'text') {
          tag['caption'] = this.currentCaption
        }
      }
      if (this.openAiOption === 'poll') {
        tag['actions'] = 'choose'
        tag['poll'] = this.currentPoll
      }
      if (this.paragraphTimeStamp !== '') {
        this.$store.commit('setTimelinePosition', this.paragraphTimeStamp * 1000)
      }
      let prop = { suggestedTag: true }
      tag = { ...tag, ...prop }
      this.showTagSuggestion(tag)
      this.setShowLoader(false)
    } catch (e) {
      this.setShowLoader(false)
    }
  }

  get suggestedLinksImage() {
    return this.suggestedURLs
  }
}
