var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex justify-between items-center" }, [
      !["custom"].includes(_vm.openAiOption)
        ? _c(
            "div",
            {
              staticClass:
                "ba bw1 br4 b--transparent bg-black-20 flex items-center",
            },
            _vm._l(_vm.searchLibraries, function (item) {
              return _c(
                "label",
                {
                  staticClass: "f6 pv1 ph2 silver link pointer mr1",
                  class:
                    _vm.selectedImageTab === item.LibraryName
                      ? "ba br4 b--transparent bg-black-80 light-gray"
                      : "",
                },
                [
                  _c("input", {
                    staticClass: "input-reset",
                    attrs: { type: "radio" },
                    domProps: {
                      checked: _vm.selectedImageTab === item.LibraryName,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleChangeTab(item.LibraryName)
                      },
                    },
                  }),
                  _vm._v(_vm._s(item.name)),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]),
    _vm.isOpenAiLoading ||
    _vm.isPublicImageLoading ||
    _vm.isLibImageLoading ||
    _vm.isDalleLoading ||
    _vm.isStabilityLoading ||
    _vm.tempLoader
      ? _c("div", { staticClass: "flex justify-center mt5" }, [_vm._m(0)])
      : _c(
          "div",
          [
            _vm.imageList.length === 0 || _vm.tags.length === 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "w-100 flex flex-column justify-center items-center",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/imgae-search.svg"),
                        alt: "",
                        width: "250",
                        height: "250",
                      },
                    }),
                    _c("div", { staticClass: "f5 silver" }, [
                      _vm._v(
                        "Oops! No images found.. Try again with different query"
                      ),
                    ]),
                  ]
                )
              : _vm.imageList.length
              ? _c("ImagesList", {
                  attrs: {
                    isMaximized: _vm.isMaximized,
                    selectedRangeStart: _vm.selectedRangeStart,
                    selectedRangeEnd: _vm.selectedRangeEnd,
                    showCreateTagButton: "true",
                    totalImages: _vm.totalPublicImageList,
                    imageSources: _vm.imageList,
                  },
                  on: {
                    handleNavigation: _vm.handleNavigation,
                    handleSelectImage: _vm.onCreateTagFromUnsplash,
                  },
                })
              : _vm._e(),
            _vm.openAiOption === "custom"
              ? _c(
                  "div",
                  { staticClass: "flex items-center w-100" },
                  [
                    _c("div", { staticClass: "w-90 relative" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.openAiPrompt,
                            expression: "openAiPrompt",
                          },
                        ],
                        staticClass:
                          "input-reset bg-dark-gray light-gray bn pv2 br2 pl2 pr2 w-100 f6",
                        attrs: {
                          type: "text",
                          placeholder: "Enter the prompt",
                        },
                        domProps: { value: _vm.openAiPrompt },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.openAiPrompt = $event.target.value
                          },
                        },
                      }),
                      !_vm.openAiPrompt
                        ? _c(
                            "i",
                            {
                              staticClass: "material-icons silver absolute f4",
                              staticStyle: { top: "5px", right: "5px" },
                            },
                            [_vm._v("search")]
                          )
                        : _c(
                            "i",
                            {
                              staticClass:
                                "material-icons silver absolute pointer dim f4",
                              staticStyle: { top: "5px", right: "5px" },
                              on: { click: _vm.clearOpenAiPrompt },
                            },
                            [_vm._v("close")]
                          ),
                    ]),
                    _c("BaseButtonBorder", {
                      staticClass: "ml3",
                      attrs: {
                        text: "search",
                        onClick: _vm.customOpenAiSearch,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            "custom" === _vm.openAiOption
              ? _c("div", { staticClass: "white mt3" }, [
                  _c("span", { staticClass: "fw5" }, [
                    _vm._v(" Open Ai Result: "),
                  ]),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.openAiResult.replace(/\r?\n/gi, "<br/>")
                      ),
                    },
                  }),
                ])
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "building-blocks mt5" }, [
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }