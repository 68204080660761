import { templateStyles, templateTypes } from './constants'
export const getTemplateStyle = (template: string, canvasSize: any, customObj?: any) => {
  let style: any = {
    left: canvasSize.width / 2,
    top: canvasSize.height / 1.2,
  }

  const currentStyle =
    template === templateTypes.BASIC
      ? templateStyles.BASIC
      : template === templateTypes.BOXED
      ? templateStyles.BOXED
      : template === templateTypes.WRAP
      ? templateStyles.WRAP
      : template === templateTypes.SHADOW
      ? templateStyles.SHADOW
      : template === templateTypes.OUTLINED
      ? templateStyles.OUTLINED
      : template === templateTypes.FILLED
      ? templateStyles.FILLED
      : templateStyles.NEON

  style = {
    ...style,
    ...templateStyles.initialStyle,
    ...currentStyle,
  }

  if (customObj) {
    let customStyle: any = {
      fill: customObj.primaryColour,
      fontFamily: customObj.fontname,
      fontSize: customObj.fontsize,
      underline: customObj.underline,
      linethrough: customObj.strikeOut,
    }

    if (customObj.template === templateTypes.BOXED || customObj.template === templateTypes.WRAP) {
      customStyle = {
        ...customStyle,
        backgroundColor: customObj.backColour,
      }
    }

    if (customObj.template === templateTypes.OUTLINED || customObj.template === templateTypes.FILLED) {
      customStyle = {
        ...customStyle,
        stroke: customObj.outlineColour,
      }
    }

    if (customObj.template === templateTypes.NEON) {
      customStyle = {
        ...customStyle,
        shadow: {
          color: customObj.primaryColour,
          offsetX: 0,
          offsetY: 10,
          blur: 10,
          opacity: 1,
        },
      }
    }

    customStyle = { ...customStyle, ...getPosition(customObj.alignment, canvasSize) }
    customObj.bold && (customStyle['fontWeight'] = 'bold')
    customObj.italic && (customStyle['fontWeight'] = 'italic')
    customObj.underline && (customStyle['underline'] = true)

    style = { ...style, ...customStyle }
  }

  return style
}

const getPosition = (alignment: number, canvasSize: any) => {
  return alignment === 2
    ? { top: canvasSize.height / 1.2, left: canvasSize.width / 2 }
    : alignment === 8
    ? {
        top: canvasSize.height / 5,
        left: canvasSize.width / 2,
      }
    : {
        top: canvasSize.height / 2,
        left: canvasSize.width / 2,
      }
}
