

























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import AdoriService from '@/services/adori'
import ImagesList from '@/components/Common/Images/ImagesList.vue'
import TagSuggestionsLinks from '@/components/Tags/Suggestions/TagSuggestionsLinks.vue'
import get from 'lodash.get'
import AdoriTag from '@/components/Tags/AdoriTag.vue'

// constants
import { searchImageTypes, imageTypes } from './Suggestions'
import { computed, reactive, ref, SetupContext, watch } from '@vue/composition-api'

import { useGetDalleResults, useGetOpenAiContext, useGetStabilityResults } from '@/hooks/openAi'
import { useGetLibImages, useGetPublicImages } from '@/hooks/image'

@Component({
  components: {
    ImagesList,
    TagSuggestionsLinks,
    AdoriTag,
  },
  setup(props: any, { root, emit }: SetupContext) {
    // Open ai code
    const openAiOption: any = computed(() => props.openAiOption)
    const paragraph = computed(() => props.paragraph)
    const topic = computed(
      () =>
        `without any extra text in response, write a short stability image prompt for this description: ${paragraph.value}. Give only prompt as response.`
    )
    const isEnabled = computed(() => !!paragraph.value)
    const currentCaption = ref('')
    const customPrompt = ref('')
    let currentPoll: any = reactive({})
    const openAiParams = reactive({
      topic: topic,
      context: openAiOption,
    })
    const { data: openAiResult, isFetching: isOpenAiLoading } = useGetOpenAiContext(
      openAiParams,
      {
        enabled: isEnabled,
      },
      (data: any) => {
        root.$store.commit('setOpenAiResult', data)
        emit('search', typeof data === 'object' ? data.topic + '?' : data)
        typeof data === 'object' ? (currentPoll = data) : (currentCaption.value = data)
      }
    )
    watch(openAiResult, (curVal: any, oldVal: any) => {
      if (curVal) {
        emit('search', typeof curVal === 'object' ? curVal.topic + '?' : curVal)
        typeof curVal === 'object' ? (currentPoll = curVal) : (currentCaption.value = curVal)
      }
    })

    // Image Search code
    const imageSearchText = computed(() => props.imageSearchText)
    const selectedImageTab = ref('UNSPLASH')
    const imageSearchParams = reactive({
      query: imageSearchText,
      limit: 10,
      offset: 0,
      searchLibrary: selectedImageTab,
    })

    const isPublicImageSearchActive = computed(
      () => !['MY_LIBRARY', 'STABILITY'].includes(selectedImageTab.value) && imageSearchText.value.trim() !== ''
    )
    const { data: publicImageData, isFetching: isPublicImageLoading } = useGetPublicImages(imageSearchParams, {
      enabled: isPublicImageSearchActive,
    })

    const libImageParams = reactive({
      limit: 10,
      offset: 0,
    })
    const isLibImageActive = computed(() => selectedImageTab.value === 'MY_LIBRARY')
    const { data: libImageData, isFetching: isLibImageLoading } = useGetLibImages(libImageParams, {
      enabled: isLibImageActive,
    })

    const isDalleActive = computed(() => ['DALLE'].includes(selectedImageTab.value) && !!imageSearchText.value)
    const dalleParams = reactive({
      prompt: imageSearchText,
      width: 1024,
      height: 1024,
    })
    const { data: dalleResults, isFetching: isDalleLoading } = useGetDalleResults(dalleParams, {
      enabled: isDalleActive,
    })
    const stabilityParams = reactive({
      prompt: imageSearchText,
      id: null,
    })
    const interval: any = ref(false)
    const isStabilityActive = computed(() => ['STABILITY'].includes(selectedImageTab.value) && !!imageSearchText.value)
    const { data: stabilityResults, isFetching: isStabilityLoading } = useGetStabilityResults(
      stabilityParams,
      {
        enabled: isStabilityActive,
      },
      interval,
      (data: any) => {
        if (data.status === 'processing' || data.status === null) {
          stabilityParams.id = data.id
          interval.value = true
        } else {
          stabilityParams.id = null
          interval.value = false
        }
      }
    )

    return {
      customPrompt,
      openAiResult,
      isOpenAiLoading,
      currentPoll,
      currentCaption,
      imageSearchParams,
      selectedImageTab,
      publicImageData,
      isPublicImageLoading,
      libImageData,
      isLibImageLoading,
      libImageParams,
      dalleResults,
      isDalleLoading,
      stabilityResults,
      isStabilityLoading,
    }
  },
})
export default class TagImageSearch extends Vue {
  @Prop(String) filter!: string
  @Prop(String) searchText!: string
  @Prop(String) paragraph!: string
  @Prop(String) audioUid!: string
  @Prop(Function) onCurrentTime!: Function
  @Prop(Boolean) isMaximized!: boolean
  @Prop() paragraphTimeStamp!: any
  @Prop() openAiOption!: any
  @Prop() imageSearchText!: any

  // for suggested links
  @Prop(String) tabType!: string
  @Prop(Number) currentTime!: number

  @Getter networkId!: string
  @Getter userId!: string

  @Action setShowLoader!: any
  @Action showTagSuggestion!: Function

  openAiPrompt = ''
  tags: any = []

  customPrompt!: string
  selectedImageTab!: string
  openAiResult!: any
  isOpenAiLoading!: boolean
  currentPoll!: any
  currentCaption!: any
  imageSearchParams!: any
  publicImageData!: any
  isPublicImageLoading!: boolean
  libImageData!: any
  isLibImageLoading!: boolean
  libImageParams!: any
  dalleResults!: any
  isDalleLoading!: boolean
  stabilityResults!: any
  isStabilityLoading!: any
  tempLoader = false

  get searchLibraries() {
    const list = [
      ...searchImageTypes,
      {
        LibraryName: 'STABILITY',
        name: 'AI',
      },
    ]
    return list
  }

  get imageList() {
    return this.selectedImageTab === 'MY_LIBRARY'
      ? get(this.libImageData, 'data', []).map((img: any) => ({
          url: img.url,
          previewUrl: img.thumbnailURL,
          source: 'adori',
          id: img.id,
        }))
      : ['DALLE'].includes(this.selectedImageTab)
      ? get(this, 'dalleResults', []).map((img: any) => ({
          url: img.url,
          previewUrl: img.url,
          source: 'dalle',
          id: crypto.randomUUID(),
        }))
      : ['STABILITY'].includes(this.selectedImageTab)
      ? get(this, 'stabilityResults.output', []).map((img: any) => ({
          url: img,
          previewUrl: img,
          source: 'stability',
          id: crypto.randomUUID(),
        }))
      : get(this.publicImageData, 'data', []).map((img: any) => ({
          url: img.urls.full,
          previewUrl: img.urls.thumb,
          source: 'unsplash',
          id: img.id,
          user: img.user || null,
        }))
  }

  get totalPublicImageList() {
    return this.selectedImageTab === 'MY_LIBRARY'
      ? get(this.libImageData, 'count', 0)
      : get(this.publicImageData, 'count', 0)
  }

  get selectedRangeStart() {
    return this.selectedImageTab === 'MY_LIBRARY' ? this.libImageParams.offset + 1 : this.imageSearchParams.offset + 1
  }

  get selectedRangeEnd() {
    return this.selectedImageTab === 'MY_LIBRARY' ? this.libImageParams.offset + 10 : this.imageSearchParams.offset + 10
  }

  @Watch('imageList')
  handleImageList() {
    this.tempLoader = true
    if (this.imageList.length === 0) this.tempLoader = false
    this.tags = []
    this.imageList.map(async (img: any, index: number) => {
      const aspectRatio = await this.getImgMeta(img.previewUrl)
      const tag: any = {
        id: index,
        image: {
          url: img.url,
          thumbnailURL: img.previewUrl,
          urls: {
            full: img.previewUrl,
            regular: img.previewUrl,
            small: img.previewUrl,
            thumb: img.previewUrl,
          },
        },
        orientation: this.getOrientation(aspectRatio),
      }

      if (this.openAiOption === 'image') tag['actions'] = 'click'

      if (this.openAiOption === 'poll') {
        tag['actions'] = 'choose'
        tag['poll'] = this.currentPoll
      }
      if (this.openAiOption === 'text') {
        tag['actions'] = 'create_note'
        tag['notetext'] = this.currentCaption
        tag['style'] = {
          fontStyle: 1,
          imageOpacity: 1,
          topMarginPercentage: 1,
        }
      }
      this.tags.push(tag)
      this.tempLoader = false
    })
  }

  handleChangeTab(tab: string) {
    this.selectedImageTab = tab
    this.imageSearchParams.offset = 0
  }

  clearOpenAiPrompt() {
    this.openAiPrompt = ''
  }

  customOpenAiSearch() {
    this.customPrompt = this.openAiPrompt
  }

  handleNavigation(nav: string) {
    if (this.selectedImageTab === 'MY_LIBRARY') {
      nav === 'next' && this.libImageParams.offset <= this.totalPublicImageList && (this.libImageParams.offset += 10)
      nav === 'prev' && this.libImageParams.offset >= 10 && (this.libImageParams.offset -= 10)
    } else {
      nav === 'next' &&
        this.imageSearchParams.offset <= this.totalPublicImageList &&
        (this.imageSearchParams.offset += 10)
      nav === 'prev' && this.imageSearchParams.offset >= 10 && (this.imageSearchParams.offset -= 10)
    }
  }

  getImgMeta(src: string) {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve(img.width / img.height)
      img.onerror = reject
      img.src = src
    })
  }
  getOrientation(ratio: any) {
    if (ratio > 1.4) return 'LANDSCAPE'
    else if (ratio < 0.8) return 'PORTRAIT'
    else if (ratio >= 0.8 && ratio <= 1.4) return 'SQUARE'
    else return 'LANDSCAPE'
  }

  async onCreateTagFromUnsplash(result: any) {
    this.setShowLoader(true)
    try {
      const imageUrl = result.url
      const response: any = await AdoriService.uploadImage(this.networkId, {
        url: imageUrl,
      })
      const aspectRatio = await this.getImgMeta(response.originalUrl)
      let tag: any = {
        userId: this.userId,
        imageId: response.id,
        shareable: false,
        saveable: false,
        style: {
          fontStyle: 1,
          topMarginPercentage: 1,
          imageOpacity: 0,
        },
        orientation: this.getOrientation(aspectRatio),
      }
      if (['image', 'text'].includes(this.openAiOption)) {
        tag['actions'] = 'click'
        if (this.openAiOption === 'text') {
          tag['caption'] = this.currentCaption
        }
      }
      if (this.openAiOption === 'poll') {
        tag['actions'] = 'choose'
        tag['poll'] = this.currentPoll
      }
      if (this.paragraphTimeStamp !== '') {
        this.$store.commit('setTimelinePosition', this.paragraphTimeStamp * 1000)
      }
      let prop = { suggestedTag: true }
      tag = { ...tag, ...prop }
      this.showTagSuggestion(tag)
      this.setShowLoader(false)
    } catch (e) {
      this.setShowLoader(false)
    }
  }

  @Watch('openAiOption')
  handleChangeOption() {
    this.openAiPrompt = ''
    this.customPrompt = ''
  }
}
