




























































































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import TagSuggestionsTranscriptSelectionMenu from '@/components/Tags/Suggestions/TagSuggestionsTranscriptSelectionMenu.vue'
import TagSuggestionsSearchImages from '@/components/Tags/Suggestions/TagSuggestionsSearchImages.vue'
import TagImageSearch from '@/components/Tags/Suggestions/TagImageSearch.vue'
import TagSuggestionsLinks from '@/components/Tags/Suggestions/TagSuggestionsLinks.vue'
import { uploadStatus } from '@/components/Publish/publish'
import ModalContent from '@/components/Modals/ModalContent.vue'
import ModalAutomationType from '@/components/Modals/ModalAutomationType.vue'
import { getQueryParam } from '@/utils/misc'
import { secondsToMmss } from '@/utils/time'
import { formatOpenAiCaption } from '@/utils/misc'
import get from 'lodash.get'
import debounce from 'lodash.debounce'

import AdoriService from '@/services/adori'
import AdoriServiceV6 from '@/services/adori_v6'
import { capitalizeFirstLetter } from '@/utils/misc'
import { computed, ref, SetupContext } from '@vue/composition-api'
import { transcriptQuery, useGenerateTranscript, useGetLanguages, useGetTranscript } from '@/hooks/transcript'
import { QueryClient, useQueryClient } from 'vue-query'
import { useGenerateSubtitles } from '@/hooks/subtitle'
import Billing from '@/mixins/Billing'
import * as Sentry from '@sentry/vue'

@Component({
  components: {
    TagSuggestionsTranscriptSelectionMenu,
    TagSuggestionsSearchImages,
    TagSuggestionsLinks,
    TagImageSearch,
    ModalContent,
    ModalAutomationType,
  },
  setup(props: any, { root }: SetupContext) {
    const queryClient = useQueryClient()
    const trackUid: any = getQueryParam('uid')
    const transcriptInterval: any = ref(false)
    const isTranscriptExist = computed(() => !!root.$store.getters.audio(trackUid).transcriptId)
    const { data: transcript, isLoading: isTranscriptLoading } = useGetTranscript(
      trackUid,
      {
        enabled: isTranscriptExist,
      },
      transcriptInterval,
      (data: any) => transcriptProcessing(data)
    )
    const { mutate: generateSubtitles, isLoading: isSubtitleGenerating } = useGenerateSubtitles()

    const transcriptProcessing = (data: any) => {
      if (data) {
        if ([uploadStatus.QUEUED, uploadStatus.STARTED].includes(data.status)) transcriptInterval.value = 10000
        else if ([uploadStatus.FINISHED].includes(data.status)) {
          transcriptInterval.value = false
          if (sessionStorage.getItem('generateSubtitles')) {
            generateSubtitles(trackUid)
            sessionStorage.removeItem('generateSubtitles')
          }
        } else {
          transcriptInterval.value = false
        }
      }
    }
    useGetLanguages()
    const { mutate: generateTranscript } = useGenerateTranscript()

    return {
      queryClient,
      transcript,
      isTranscriptLoading,
      isTranscriptExist,
      generateTranscript,
      transcriptInterval,
      transcriptProcessing,
    }
  },
})
export default class Transcript extends Mixins(Billing) {
  @Prop(String) filter!: string
  @Prop(String) audioUid!: string
  @Prop(Function)
  playSnippet!: (offset: number, duration: number, callback: Function) => void
  @Prop(Function) pauseSnippet!: () => void
  @Prop(Number) currentTime!: number
  @Prop(Function) onCurrentTime!: Function
  @Prop(Boolean) isMaximized!: boolean
  @Prop(String) selectedTagSuggestionsCategory!: string

  @Getter audio!: any
  @Getter getTranscript!: Function
  @Getter getSuggestions!: Function
  @Getter languages!: any
  @Getter audiosByUid!: any
  @Getter ytModalId!: any
  @Getter selectedEpisodeSettings!: any
  @Getter openAiPayload!: any
  @Getter networkId!: any
  @Getter isAutomationLoading!: any

  @Action showConfirm!: Function
  @Action closeModal!: Function
  @Action toggleCreditCardAlert!: Function

  scene = { image: null, video: null }
  activeHover: boolean = false
  isSceneImgFetching = false
  showContentModal = false
  showAutomationTypeModal = false
  selectedContext = 'BLOG_TEXT'
  selectedScene = {}
  isSowVideo = true

  selectedTab: string = 'SUGGESTED_IMAGES' // 'SUGGESTED_IMAGES', 'SUGGESTED_LINKS', 'SEARCH TRANSCRIPT'
  highlightSuggestions: boolean = true

  isPlayingSnippet: boolean = false

  selectedSuggestionText: string = ''
  selectedSuggestionNode: HTMLElement | null = null

  buttonDisabled = false
  buttonUploadTranscriptDisabled = false

  selectedLanguage: string = 'en-US'

  currentTimeStamp: any = null

  episodeKeywords = []
  startTimeSec = 0
  endTimeSec = 0
  paragraphTimeStamp: any = ''
  openAiOption = 'image'
  paragraph = ''
  searchQuery = ''
  imageSearchText = ''

  queryClient!: QueryClient
  transcript!: any
  isTranscriptLoading!: boolean
  isTranscriptExist!: boolean
  generateTranscript!: any
  transcriptInterval!: any
  transcriptProcessing!: any

  async mounted() {
    this.transcriptProcessing(this.transcript)
    if (this.ytModalId && this.selectedEpisodeSettings[this.ytModalId]) {
      this.startTimeSec = this.selectedEpisodeSettings[this.ytModalId].startTimeSec
      this.endTimeSec = this.selectedEpisodeSettings[this.ytModalId].endTimeSec
    }
    this.resetSentences()
    this.selectedLanguage = this.episode.language || ''
    this.$watch(
      () => this.transcript?.sentences,
      () => {
        this.resetSentences()
      }
    )
  }

  resetSentences() {
    if (this.sentences.length) {
      this.currentTimeStamp = this.sentences[0].words
      // @ts-ignore
      this.paragraph = this.$refs['0_paragraph'][0].textContent.replace(/\s\s+/g, ' ')
      this.paragraphTimeStamp = +this.sentences[0].words[0].start_time.replace('s', '')
    }
  }

  debouncedSearch = debounce(() => {
    this.setImageSearchText()
  }, 1000)

  setImageSearchText(query?: string) {
    if (query) {
      this.searchQuery = query
      this.imageSearchText = query
    } else {
      this.imageSearchText = this.searchQuery
      this.clearAllFilters()
    }
  }

  async clearSearchQuery() {
    this.searchQuery = ''
    this.imageSearchText = ''
    this.clearAllFilters()
  }

  switchTab(to: string) {
    this.selectedTab = to
  }

  onTimeClick(words: any, ref: string) {
    this.clearAllFilters()
    this.currentTimeStamp = words
    this.paragraphTimeStamp = +words[0].start_time.replace('s', '')
    const scroll = parseInt(words[0].start_time) > 5 ? parseInt(words[0].start_time) - 5 : parseInt(words[0].start_time)
    this.$emit('scroll-timeline', scroll)
    // @ts-ignore
    const paragraph = this.$refs[ref][0].textContent.replace(/\s\s+/g, ' ')

    this.paragraph = paragraph.length > 2000 ? paragraph.replace(/^(.{2000}[^\s]*).*/, '$1') : paragraph
  }

  clearAllFilters() {
    this.currentTimeStamp = null
    this.paragraphTimeStamp = ''
    this.paragraph = ''
  }

  handleTrancriptTab() {
    this.selectedTagSuggestionsCategory === 'TRANSCRIPT' &&
      // @ts-ignore
      this.clearAllFilters()
    this.$emit('suggestion-category', 'TRANSCRIPT')
  }
  handleEpisodeTab() {
    this.$emit('suggestion-category', 'EPISODE_INFO')
  }

  capitalizeFirstLetter(str: string) {
    return capitalizeFirstLetter(str)
  }

  get tagEditStoreData() {
    return this.$store.state.modal.tagEdit
  }

  get episode() {
    return this.audio(this.audioUid)
  }

  get transcriptStatus() {
    return get(this.transcript, 'status', null)
  }

  get transcriptStatusText() {
    if (this.transcriptStatus) {
      return [uploadStatus.STARTED, uploadStatus.QUEUED].includes(this.transcriptStatus)
        ? 'Generating Transcript ...'
        : [uploadStatus.FAILED].includes(this.transcriptStatus)
        ? 'Regenerate Transcript'
        : 'Generate Transcript'
    }

    return this.buttonDisabled ? 'Generating Transcript ...' : 'Generate Transcript'
  }

  get uploadTranscriptText() {
    if (this.transcriptStatus) {
      return [uploadStatus.STARTED, uploadStatus.QUEUED].includes(this.transcriptStatus)
        ? 'Uploading Transcript ...'
        : [uploadStatus.FAILED].includes(this.transcriptStatus)
        ? 'Re-upload Transcript'
        : 'Upload Transcript'
    }

    return this.buttonUploadTranscriptDisabled ? 'Uploading Transcript ...' : 'Upload Transcript'
  }

  get suggestionsStatus() {
    return this.episode && this.episode.transSuggTaskStatus
  }

  get sentences() {
    return get(this.transcript, 'sentences', [])
  }

  get languageSelector() {
    const options: any = []

    this.languages.forEach((lang: any) => {
      options.push(`
		  <option
			style="width: 150px;" class="f6" value="${lang.code}" key="${lang.code}">
			${lang.name}
		  </option>`)
    })

    return `
		<div class="mt3 scrollbar w5">
		  <div class="flex justify-between">
			<div class="pb2 gray f6">Episode language
			<BaseTooltip info='Language used in audio.' /> </div>
		  </div>
		  <div class=" scrollbar relative">
			<select
			  id="langSelector"
			  :disabled="languageDisabled"
			  style="width: 250px;"
			  class="input-reset light-gray bn br2 pa3 pv2 shadow-2 db arrow w-100 mb2 w2 f6  bg-adori-very-light-gray">
			  ${options}
			</select>
			<i class="material-icons absolute light-gray" style="top:5px; right: 10px;">
			  arrow_drop_down
			</i>
		  </div>
		</div>`
  }

  formattedTime(time: string) {
    const value = parseInt(time.replace('s', '')) * 1000
    return value === 0 ? '00:00' : `${secondsToMmss(value / 1000)}`
  }

  formatCategory(category: string) {
    let formattedString = category.toLowerCase().replace(/_/g, ' ')
    formattedString = formattedString[0].toUpperCase() + formattedString.slice(1)
    return formattedString
  }

  onPlaySnippet(text: string, startTime: string, endTime: string) {
    const startMillis = parseFloat(startTime) * 1000
    const endMillis = parseFloat(endTime) * 1000

    this.playSnippet(startMillis, endMillis - startMillis, () => {
      this.isPlayingSnippet = false
    })
    this.isPlayingSnippet = true
  }

  onPauseSnippet() {
    this.pauseSnippet()
    this.isPlayingSnippet = false
  }

  onSearchImage(text: string, startTime: string, endTime: string) {
    this.switchTab('SUGGESTED_IMAGES')
    this.selectedSuggestionText = text
  }

  async onCreateTag(text: string, startTime: string, endTime: string) {
    await this.$store.dispatch('showTagEdit')
    await this.$store.dispatch('changeTagCaption', text.slice(0, 200))
  }

  async handleGenerate() {
    if (this.showPopup) {
      this.toggleCreditCardAlert(true)
      return 0
    }
    setTimeout(() => {
      const ele = document.getElementById('langSelector') as HTMLElement
      // @ts-ignore
      ele.value = this.selectedLanguage
      ele.addEventListener('change', (e: any) => {
        this.selectedLanguage = e.target.value
      })
    }, 500)
    this.showConfirm({
      title: 'Generate Transcript for this episode',
      description: `<p>Transcription for this audio track will be made available, however this process can take some time depending on the length and quality of the audio ${this.languageSelector}`,
      yesButtonText: 'Confirm',
      noButtonText: 'Cancel',
      onConfirm: async () => {
        try {
          this.buttonDisabled = true
          this.closeModal()
          this.generateTranscript({
            trackUid: this.audioUid,
            language: this.selectedLanguage || 'en-US',
          })
          sessionStorage.setItem('generateSubtitles', 'YES')
        } catch (error) {
          this.buttonDisabled = false
        }
      },
      onCancel: async () => {
        this.closeModal()
      },
    })
  }

  handleUpload() {
    this.$store.dispatch('showFileUploader', {
      accept: '*',
      onChange: this.loadTranscriptFile,
      dontCheck: true,
    })
  }

  fileType(file: any) {
    const filename = file.name
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename
  }

  async loadTranscriptFile() {
    const file = this.$store.getters.selectedFile

    if (this.fileType(file) !== 'srt') {
      this.$store.dispatch('pushNotification', {
        text: 'Please upload only SRT files',
        type: 'WARNING',
      })
      return
    }
    try {
      if (file) {
        this.buttonUploadTranscriptDisabled = true
        await AdoriService.uploadTranscript(this.networkId, this.audioUid, file)
        this.paragraph = ''
        this.queryClient.invalidateQueries([transcriptQuery.TRANSCRIPT, this.networkId, this.audioUid])
        this.buttonUploadTranscriptDisabled = false
      }
    } catch (error) {
      this.buttonUploadTranscriptDisabled = false
    }
  }

  onSearchGoogle(text: string) {
    window.open(`https://www.google.com/search?q=${text}`, '_blank')
  }

  onSuggestionClicked(event: Event) {
    let srcElement: any = event.srcElement
    if (this.highlightSuggestions && event.srcElement && srcElement.className.indexOf('highlight') !== -1) {
      this.selectedSuggestionNode = event.srcElement as HTMLElement
    }
  }

  isTimeClicked(sentence: any) {
    if (!this.currentTimeStamp) return false
    if (!sentence) return false
    return this.currentTimeStamp[0].start_time === sentence.words[0].start_time
  }

  generateAITags() {
    this.$store.dispatch('generateAiImages', { audioTrack: this.episode, sentences: this.sentences })
  }

  toggleBlogImage(words: any, ref: string, text: string, start_time: string, end_time: string) {
    this.onTimeClick(words, ref)
    this.selectedScene = {
      text: text,
      start_time: start_time,
      end_time: end_time,
    }
    this.selectedContext = 'BLOG_TEXT'
    this.showContentModal = true
    // this.isSowVideo = true
  }
  handleRemoveSceneImage() {
    this.$emit('removeSceneImage', this.scene)
  }
  handleOver(e: any) {
    e.target.play()
  }
  handleOut(e: any) {
    e.target.pause()
  }

  getImgMeta(src: string) {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve({ originalImageWidth: img.width, originalImageHeight: img.height })
      img.onerror = reject
      img.src = src
    })
  }

  toMillisTime(timeString: string) {
    const timeInSeconds = parseFloat(timeString.replace('s', ''))
    const timeInMillis = timeInSeconds * 1000
    return timeInMillis
  }

  async createAITag(image: any, sentence: any, title = 'AI Tag', type: string) {
    let tagItem: any
    let tagId: any
    let video: any
    if (type === 'image') {
      const imageMeta: any = await this.getImgMeta(image.originalUrl)

      const originalImageWidth = imageMeta.originalImageWidth
      const originalImageHeight = imageMeta.originalImageHeight

      let imageWidth, imageHeight

      if (originalImageWidth >= originalImageHeight) {
        // Landscape or square image
        imageWidth = 1080
        imageHeight = 1080 / (originalImageWidth / originalImageHeight)
      } else {
        // Portrait image
        imageHeight = 1080
        imageWidth = 1080 * (originalImageWidth / originalImageHeight)
      }

      // Calculate scaleX and scaleY based on original image dimensions
      const scaleX = imageWidth / originalImageWidth
      const scaleY = imageHeight / originalImageHeight

      // Draw the image in the center of the canvas
      const x = (1080 - imageWidth) / 2
      const y = (1080 - imageHeight) / 2

      this.tagEditStoreData.data.tagType = 'photo'
      this.tagEditStoreData.data.canvasData = {
        version: '4.6.0',
        objects: [
          {
            type: 'rect',
            version: '4.6.0',
            originX: 'left',
            originY: 'top',
            left: 0,
            top: 0,
            width: 1080,
            height: 1080,
            fill: {
              type: 'linear',
              coords: {
                x1: 0,
                y1: 540,
                x2: 1920,
                y2: 0,
              },
              colorStops: [
                {
                  offset: 0,
                  color: '#21D4FD',
                },
                {
                  offset: 1,
                  color: '#B721FF',
                },
              ],
              offsetX: 0,
              offsetY: 0,
              gradientUnits: 'pixels',
              gradientTransform: null,
            },
            stroke: null,
            strokeWidth: 1,
            strokeDashArray: null,
            strokeLineCap: 'butt',
            strokeDashOffset: 0,
            strokeLineJoin: 'miter',
            strokeUniform: false,
            strokeMiterLimit: 4,
            scaleX: 1,
            scaleY: 1,
            angle: 0,
            flipX: false,
            flipY: false,
            opacity: 1,
            shadow: null,
            visible: true,
            backgroundColor: '',
            fillRule: 'nonzero',
            paintFirst: 'fill',
            globalCompositeOperation: 'source-over',
            skewX: 0,
            skewY: 0,
            rx: 0,
            ry: 0,
            id: 'artboard',
          },
          {
            type: 'image',
            version: '4.6.0',
            originX: 'left',
            originY: 'top',
            left: x,
            top: y,
            width: originalImageWidth,
            height: originalImageHeight,
            fill: 'rgb(0,0,0)',
            stroke: null,
            strokeWidth: 0,
            strokeDashArray: null,
            strokeLineCap: 'butt',
            strokeDashOffset: 0,
            strokeLineJoin: 'miter',
            strokeUniform: false,
            strokeMiterLimit: 4,
            scaleX: scaleX,
            scaleY: scaleY,
            angle: 0,
            flipX: false,
            flipY: false,
            opacity: 1,
            shadow: null,
            visible: true,
            backgroundColor: '',
            fillRule: 'nonzero',
            paintFirst: 'fill',
            globalCompositeOperation: 'source-over',
            skewX: 0,
            skewY: 0,
            cropX: 0,
            cropY: 0,
            src: image.originalUrl,
            crossOrigin: 'anonymous',
            filters: [],
          },
        ],
      }

      this.tagEditStoreData.tagTitle = 'AI TAG'
      this.tagEditStoreData.data.orientation = 'SQUARE'
      this.tagEditStoreData.data.imageId = image.id
      this.tagEditStoreData.data.imageData = ''
      this.tagEditStoreData.data.videoId = null
      tagId = await this.$store.dispatch('uploadTag', false)
      tagItem = {
        tagId,
        offsetMillis: this.toMillisTime(sentence.start_time),
        durationMillis: this.toMillisTime(sentence.end_time) - this.toMillisTime(sentence.start_time),
      }
    }

    if (type === 'video') {
      video = image
      this.tagEditStoreData.tagTitle = 'AI TAG'
      this.tagEditStoreData.data.orientation = 'SQUARE'
      this.tagEditStoreData.data.imageId = null
      this.tagEditStoreData.data.videoSrc = video.originalUrl
      this.tagEditStoreData.data.videoId = video.id
      this.tagEditStoreData.data.canvasData = {}
      this.tagEditStoreData.data.tagType = 'photo'

      tagId = await this.$store.dispatch('uploadTag', false)
      tagItem = {
        tagId,
        offsetMillis: this.toMillisTime(sentence.start_time),
        durationMillis: this.toMillisTime(sentence.end_time) - this.toMillisTime(sentence.start_time),
      }
    }

    await this.$store.dispatch('addAudioTag', {
      audioUid: this.audioUid,
      tagPosition: tagItem,
    })

    this.$store.commit('setTimelineEdited', true)
  }

  handleAutomation() {
    this.showAutomationTypeModal = true
  }

  async automateAITags(value: any) {
    this.showAutomationTypeModal = false
    const type = value.type

    this.isSceneImgFetching = true
    this.$store.commit('setIsAutomationLoading', true)
    this.$store.commit('setTimelineLoader', true)
    try {
      let image: any
      let video: any

      for (const [index, sentence] of this.sentences.entries()) {
        let openAi!: any

        if (type === 'images') {
          openAi = await AdoriServiceV6.openAi(this.networkId, {
            topic: `give one or two comma separated keywords that encapsulates the main visual elements and characteristics of the described scene. Scene_description=${sentence.text}. Give only keywords as response. Translate non-English keywords to English while maintaining cultural context.`,
            context: 'image',
          })

          const unsplashRes: any = await AdoriService.searchImages(this.networkId, 'unsplash', {
            limit: 10,
            offset: 0,
            query: formatOpenAiCaption(openAi.result),
          })
          if (unsplashRes.data.length) {
            const randomNumber = Math.floor(Math.random() * 6)
            image = await AdoriService.uploadImage(this.networkId, {
              url: unsplashRes.data[randomNumber].urls.full,
            })
            //   const modifiedScene = { ...scene, image:  }
            //   this.updateScene(modifiedScene)
          } else {
            const googleRes: any = await AdoriService.searchImages(this.networkId, 'google', {
              limit: 10,
              offset: 0,
              query: formatOpenAiCaption(openAi.result),
            })
            if (googleRes.data.length) {
              const randomNumber = Math.floor(Math.random() * 6)
              image = await AdoriService.uploadImage(this.networkId, {
                url: googleRes.data[randomNumber].urls.full,
              })
              // const modifiedScene = { ...scene, image: googleRes.data[randomNumber].urls.full }
              // this.updateScene(modifiedScene)
            }
          }
          await this.createAITag(image, sentence, formatOpenAiCaption(openAi.result), 'image')
        } else if (type === 'ai') {
          openAi = await AdoriServiceV6.openAi(this.networkId, {
            topic: `"""scene description: ${sentence.text} """ \n Without any extra text in response, write a short image prompt in english for scene description. `,
            context: 'image',
          })

          const res: any = await AdoriServiceV6.stabilityResults(this.networkId, {
            prompt: formatOpenAiCaption(openAi.result),
            aspect_ratio: value.aiParams.aspect_ratio,
            model: 'flux-schnell',
            width: value.aiParams.width,
            height: value.aiParams.height,
          })
          image = await AdoriService.uploadImage(this.networkId, {
            url: res.url,
          })
          await this.createAITag(image, sentence, formatOpenAiCaption(openAi.result), 'image')
        } else if (type === 'videos') {
          try {
            openAi = await AdoriServiceV6.openAi(this.networkId, {
              topic: `give  one or two comma separated keywords that encapsulates the main visual elements and characteristics of the described scene. Scene_description=${sentence.text}. Give only keywords as response.`,
              context: 'image',
            })
            const randomNumber = Math.floor(Math.random() * 6)
            const pexelsRes = await AdoriServiceV6.searchVideos(this.networkId, 'pexels', {
              limit: 10,
              offset: 0,
              query: formatOpenAiCaption(openAi.result),
            })
            video = await AdoriServiceV6.uploadVideoUrl(
              this.networkId,
              get(pexelsRes.data[randomNumber], 'urls.large.url', ''),
              get(pexelsRes.data[randomNumber], 'urls.tiny.thumbnail', '')
            )
            await this.createAITag(
              { video: video.originalUrl, id: video.id },
              sentence,
              formatOpenAiCaption(openAi.result),
              'video'
            )
          } catch (error) {
            console.log(error)
            this.$store.commit('setTimelineLoader', false)
          }
        }

        this.onTimeClick(sentence.words, `${index}_paragraph`)
      }
      this.isSceneImgFetching = false
      this.$store.commit('setIsAutomationLoading', false)
      this.$store.commit('setTimelineLoader', false)
    } catch (error) {
      Sentry.captureException(error)
      this.$store.dispatch('pushNotification', {
        text: 'Auto image selection failed!! try again later',
        type: 'WARNING',
      })

      this.isSceneImgFetching = false
      this.$store.commit('setIsAutomationLoading', false)
      this.$store.commit('setTimelineLoader', false)
    }
  }
  async handleContentLoad(item: any) {
    this.showContentModal = false
    this.$store.commit('setTimelineLoader', true)
    try {
      const image = await AdoriService.uploadImage(this.networkId, {
        url: item.url,
      })
      const sentence = {
        start_time: item.start_time,
        end_time: item.end_time,
      }
      await this.createAITag(image, sentence, item.title, 'image')
      this.$store.commit('setTimelineLoader', false)
    } catch (error) {
      this.$store.commit('setTimelineLoader', false)
    }
  }
  async handleContentVideoLoad(item: any) {
    this.showContentModal = false
    this.$store.commit('setTimelineLoader', true)
    try {
      const video: any = await AdoriServiceV6.uploadVideoUrl(this.networkId, item.originalUrl, item.thumbNail)

      const sentence = {
        start_time: item.start_time,
        end_time: item.end_time,
      }
      await this.createAITag({ video: video.originalUrl, id: video.id }, sentence, item.title, 'video')
      this.$store.commit('setTimelineLoader', false)
    } catch (error) {
      this.$store.commit('setTimelineLoader', false)
    }
  }
}
