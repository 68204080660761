var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "span",
        {
          staticClass: "f6 pl1 br2 light-gray bg-adori-gray o-90 relative z-3",
        },
        [
          _c("BasePopupMenu", {
            attrs: { icon: "more_vert", items: _vm.popupMenuItems() },
          }),
          _c(
            "a",
            {
              ref: "audioOriginalDownloadRef",
              staticClass: "dn",
              attrs: { href: _vm.audioOriginalDownloadURL, download: "" },
            },
            [_vm._v("Download Original")]
          ),
          _c(
            "a",
            {
              ref: "audioDownloadRef",
              staticClass: "dn",
              attrs: { href: _vm.audioDownloadURL, download: "" },
            },
            [_vm._v("Download Audio")]
          ),
        ],
        1
      ),
      _vm.shareAudio
        ? _c("ShareModal", {
            attrs: { audio: _vm.audioData },
            on: { close: _vm.closeShare },
          })
        : _vm._e(),
      _vm.showTranscriptFormat
        ? _c("TranscriptModal", {
            attrs: { audioUid: _vm.audioUid },
            on: { close: _vm.closeTranscript },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }