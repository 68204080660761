






































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import TagSuggestionsEpisodeSelectionMenu from '@/components/Tags/Suggestions/TagSuggestionsEpisodeSelectionMenu.vue'
import TagSuggestionsSearchImages from '@/components/Tags/Suggestions/TagSuggestionsSearchImages.vue'
import TagSuggestionsLinks from '@/components/Tags/Suggestions/TagSuggestionsLinks.vue'
import get from 'lodash.get'
import debounce from 'lodash.debounce'

@Component({
  components: {
    TagSuggestionsEpisodeSelectionMenu,
    TagSuggestionsSearchImages,
    TagSuggestionsLinks,
  },
})
export default class TagSuggestionsEpisode extends Vue {
  @Prop(String) filter!: string
  @Prop(String) audioUid!: string
  @Prop(Number) currentTime!: number
  @Prop(Function) onCurrentTime!: Function
  @Prop(Boolean) isMaximized!: boolean
  @Prop(String) selectedTagSuggestionsCategory!: string

  @Getter audio!: any
  @Getter getSuggestions!: Function
  @Getter audiosByUid!: any
  @Getter openAiPayload!: any

  @Action getAudio!: Function

  selectedTab: string = 'SUGGESTED_IMAGES'
  selectedSuggestionText: string = ''
  selectedSuggestionNode: HTMLElement | null = null

  invisibleSearchText: any = ''
  openAiOption = 'image'
  paragraph = ''
  searchQuery = ''
  imageSearchText = ''

  async mounted() {
    this.description // @ts-ignore
      ? (this.paragraph = this.$refs.description.textContent)
      : // @ts-ignore
        (this.paragraph = this.$refs.episodeName.textContent)
  }

  clearAllFilters() {
    this.paragraph = ''
  }

  debouncedSearch = debounce(() => {
    this.setImageSearchText()
  }, 600)

  setImageSearchText(query?: string) {
    if (query) this.searchQuery = query
    else {
      this.imageSearchText = this.searchQuery
      this.clearAllFilters()
    }
  }

  async clearSearchQuery() {
    this.searchQuery = ''
    this.clearAllFilters()
  }

  handleTrancriptTab() {
    this.$emit('suggestion-category', 'TRANSCRIPT')
  }
  handleEpisodeTab() {
    this.selectedTagSuggestionsCategory === 'EPISODE_INFO' &&
      // @ts-ignore
      this.clearAllFilters()
    this.$emit('suggestion-category', 'EPISODE_INFO')
  }

  get episode() {
    return this.audio(this.audioUid)
  }

  get episodeName() {
    return this.episode.name
  }

  get description() {
    return this.episode.description
  }

  onSearchImage(text: string) {
    this.selectedSuggestionText = text
  }

  async onCreateTag(text: string) {
    await this.$store.dispatch('showTagEdit')
    await this.$store.dispatch('changeTagCaption', text.slice(0, 200))
  }

  onSearchGoogle(text: string) {
    window.open(`https://www.google.com/search?q=${encodeURIComponent(text)}`, '_blank')
  }
}
