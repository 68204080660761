












import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { ADORI_API_BASE_URL } from '@/constants'
import { uploadStatus } from '@/components/Publish/publish'

// modals
import ShareModal from '@/components/Audio/Modals/Share.vue'
import TranscriptModal from '@/components/Audio/Modals/Transcript.vue'
import { useGetTranscript } from '@/hooks/transcript'
import { getQueryParam } from '@/utils/misc'
import { computed } from '@vue/composition-api'
import get from 'lodash.get'

@Component({
  components: { ShareModal, TranscriptModal },
  setup(props: any) {
    const trackUid: any = getQueryParam('uid')
    const isTranscriptExist = computed(() => !!get(props, 'audio.transcriptId', false))
    const { data: transcript } = useGetTranscript(
      trackUid,
      {
        enabled: isTranscriptExist,
      },
      false
    )

    return {
      transcript,
    }
  },
})
export default class PublishButton extends Vue {
  @Prop() audio!: any
  @Prop(String) audioUid!: string
  @Prop({ type: Boolean, default: false }) fromYoutube!: boolean
  @Prop({ type: Boolean, default: false })
  disabledButton!: boolean

  @Getter token!: any

  @Action closeModal!: any
  @Action showIframeEmbed!: any
  @Action setShowLoader!: any

  @Action showEditPodcastEpisode!: any

  shareAudio = false
  showTranscriptFormat = false
  transcript!: any

  get backgroundClass() {
    return this.isPublished ? 'bg-adori-light-gray' : ''
  }

  get audioData() {
    return this.audio
  }

  get isPublished() {
    const audio = this.$store.getters.audio(this.audioUid)
    return audio.public
  }

  get audioDownloadURL() {
    const networkId = this.$store.getters.networkId
    return `${ADORI_API_BASE_URL}/networks/${networkId}/audiotracks/${this.audioUid}/audio/download?api_token=${this.token}`
  }

  get audioOriginalDownloadURL() {
    const networkId = this.$store.getters.networkId
    return `${ADORI_API_BASE_URL}/networks/${networkId}/audiotracks/${this.audioUid}/audio/download?api_token=${this.token}&original=true`
  }

  get buttonText() {
    if (this.isPublished) return 'Public'
    return 'Make public'
  }

  get transcriptStatus() {
    return this.transcript && this.transcript.status
  }

  handleButtonClicked() {
    if (!this.isPublished) {
      this.$store.dispatch('showConfirm', {
        title: 'Make this audio track public?',
        description: 'This will make the audio track visible to other users. You cannot undo this action.',
        yesButtonText: 'Yes, make the audio track public',
        noButtonText: 'No, continue editing',
        onConfirm: async () => {
          const episode = this.$store.getters.audio(this.audioUid)
          this.closeModal()
          this.setShowLoader(true)
          await this.$store.dispatch('updateAudioTrack', {
            audioUid: this.audioUid,
            payload: { public: true },
          })
          await this.$store.dispatch('getPublishedAudioUids')
          this.setShowLoader(false)
        },
      })
    }
  }

  popupMenuItems() {
    let menuItems
    if (this.fromYoutube) {
      menuItems = [
        {
          name: 'Edit audio',
          icon: 'edit',
          onClick: () => this.handleEditClick(),
        },
        {
          name: 'Download audio',
          icon: 'cloud_download',
          onClick: () => this.handleDownloadOriginalAudio(),
        },
      ]
    }
    menuItems = [
      {
        name: 'Embed on web',
        icon: 'stay_primary_portrait',
        disabled: this.disabledButton,
        onClick: () => this.handleEmbedWeb(),
      },
      {
        name: 'Share',
        icon: 'share',
        onClick: () => this.handleShareAudio(),
      },
      {
        name: 'Download Original',
        icon: 'cloud_download',
        disabled: this.disabledButton,
        onClick: () => this.handleDownloadOriginalAudio(),
      },
      {
        name: 'Download Audio',
        icon: 'cloud_download',
        disabled: this.disabledButton,
        onClick: () => this.handleDownloadAudio(),
      },
    ]
    if (this.transcriptStatus === uploadStatus.FINISHED) {
      menuItems.push({
        name: 'Download Transcript',
        icon: 'description',
        onClick: () => this.handleDownloadTranscript(),
      })
    }
    return menuItems
  }

  handleAddToPodcast() {}

  handleShareAudio() {
    this.shareAudio = true
  }

  closeShare() {
    this.shareAudio = false
  }

  handleEmbedWeb() {
    this.showIframeEmbed(this.audioUid)
  }

  handleDownloadOriginalAudio() {
    const audioDownloadLink = this.$refs.audioOriginalDownloadRef as HTMLElement
    audioDownloadLink.click()
  }

  handleDownloadAudio() {
    const audioDownloadLink = this.$refs.audioDownloadRef as HTMLElement
    audioDownloadLink.click()
  }

  handleEditClick() {
    this.handleEditEpisodeDetails()
  }

  handleEditEpisodeDetails() {
    this.showEditPodcastEpisode(this.audioUid)
  }

  handleDownloadTranscript() {
    this.showTranscriptFormat = true
  }

  closeTranscript() {
    this.showTranscriptFormat = false
  }
}
