





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
@Component
export default class TranscriptModal extends Vue {
  @Prop(String) audioUid!: string

  @Action downloadTranscript!: Function
  @Action pushNotification!: any

  transcriptFormat = 'TEXT'
  transcriptLoader = false
  downloadButtonText = 'Download'

  async handleDownload() {
    this.transcriptLoader = true
    this.downloadButtonText = 'Downloading'
    try {
      const data = await this.downloadTranscript({
        audioUid: this.audioUid,
        format: this.transcriptFormat,
      })
      const link = this.$refs.transcriptDownloadRef as HTMLAnchorElement
      link.href = data.url
      link.click()
    } catch (error) {}
    this.transcriptLoader = false
    this.$emit('close')
  }
}
