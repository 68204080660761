






















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { toReadableDate, toHms } from '@/utils/time'
import { fontStyles, getTagType } from '@/utils/tag'
import AdoriService, { getImageSrc } from '@/services/adori'

@Component
export default class TagSuggestionsLinkTagPreview extends Vue {
  @Prop(String) filter!: string
  @Prop() link!: any
  @Prop(Boolean) draggable!: boolean
  @Prop(Boolean) hasButtons!: boolean
  @Prop(String) audioUid!: string
  @Prop(Number) currentTime!: number
  @Prop(Function) onCurrentTime!: Function

  @Getter userId!: string
  @Getter networkId!: string
  @Action setShowLoader!: any

  selectedOffsetTime: number = 0
  captionAreaHeight = 185 - 33
  tagAreaHeight = 33

  toHms = toHms

  mounted() {
    this.selectedOffsetTime = (this.link.offsetMillis && this.link.offsetMillis[0]) || this.currentTime
  }

  get tag() {
    return {
      actions: 'click',
      caption: this.tagCaption,
      imageSrc: this.tagImageSrc,
      saveable: false,
      shareable: false,
      style: {
        fontStyle: 1,
        imageOpacity: 1,
        topMarginPercentage: 1,
      },
      url: this.link.url,
    }
  }

  get fontClass() {
    const index = (this.tag.style.fontStyle === 0 ? 1 : this.tag.style.fontStyle) - 1
    if (this.tagType === 'message') return fontStyles[index] + ' f-12px lh-copy'
    return fontStyles[index] + ' f-8px lh-copy'
  }

  get tagType() {
    return getTagType(this.tag)
  }

  get tagImageSrc() {
    return this.link.images[0].url || getImageSrc(this.link.imageId)
  }

  get tagCaption() {
    return this.link.caption || ''
  }

  getImgMeta(src: string) {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve(img.width / img.height)
      img.onerror = reject
      img.src = src
    })
  }

  getOrientation(ratio: any) {
    if (ratio > 1.4) return 'LANDSCAPE'
    else if (ratio < 0.8) return 'PORTRAIT'
    else if (ratio >= 0.8 && ratio <= 1.4) return 'SQUARE'
    else return 'LANDSCAPE'
  }

  async handleAddTagSuggestionToTimeline(offsetMillis: number) {
    this.setShowLoader(true)

    let imageId = this.link.imageId
    let response: any
    if (!imageId) {
      const imageUrl = this.link.images[0].url
      response = await AdoriService.uploadImage(this.networkId, {
        url: imageUrl,
      })

      imageId = response.id
    }

    const aspectRatio = await this.getImgMeta(response.originalUrl)
    const orientation = this.getOrientation(aspectRatio)

    const tag: any = await AdoriService.createTag(this.networkId, {
      actions: 'click',
      url: this.link.url,
      caption: '',
      imageId: imageId,
      shareable: true,
      saveable: true,
      style: {
        fontStyle: 1,
        topMarginPercentage: 1,
        imageOpacity: 0,
      },
      suggestionId: this.link.suggestionId,
      orientation: orientation,
    })

    if (orientation === this.filter) {
      await this.$store.dispatch('addTags', [tag])
    }

    const tagPosition = {
      tagId: tag.id,
      offsetMillis,
      tag: tag,
    }

    await this.$store.dispatch('addAudioTags', {
      audioUid: this.audioUid,
      tagPositions: [tagPosition],
    })

    this.onCurrentTime(offsetMillis / 1000)
    window.scrollTo(0, 0)

    this.setShowLoader(false)
  }

  get audio() {
    return this.$store.getters.audio(this.audioUid)
  }

  get audioCollectionUids() {
    let feedUids = []
    if (this.audio && this.audio.audioCollections) {
      feedUids = this.audio.audioCollections.map((audioCol: any) => audioCol.uid)
    }
    return feedUids
  }

  get isInsertTagEpisodeAllowed() {
    if (this.audioCollectionUids.length > 0) {
      for (const feedUid of this.audioCollectionUids) {
        if (this.$permissions.isInsertTagEpisodeShowAllowed(feedUid)) {
          return true
        }
      }
      return false
    } else {
      return this.$permissions.isInsertTagEpisodeAllowed()
    }
  }
}
