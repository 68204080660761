var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.edit
      ? _c("textarea", {
          ref: "editText",
          staticClass:
            "bg-adori-very-light-gray bw0 br2 pv1 f6 white text-area",
          attrs: { type: "text" },
          domProps: { value: _vm.valueLocal },
          on: {
            blur: function ($event) {
              _vm.valueLocal = $event.target.value
              _vm.$parent.edit = false
              _vm.$emit("change-text", _vm.valueLocal)
            },
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              _vm.valueLocal = $event.target.value
              _vm.$parent.edit = false
              _vm.$emit("change-text", _vm.valueLocal)
            },
          },
        })
      : _c("div", { staticClass: "flex mv1", on: { click: _vm.editMode } }, [
          _c("div", { staticClass: "edit" }, [_vm._v(_vm._s(_vm.valueLocal))]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }