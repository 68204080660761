

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
@Component
export default class SubtileText extends Vue {
  @Prop(String) value!: string
  @Prop(Boolean) edit!: boolean

  valueLocal = this.value

  editMode() {
    // @ts-ignore
    this.$parent.edit = true
  }

  @Watch('value')
  onValueChange() {
    this.valueLocal = this.value
  }
  @Watch('edit')
  onEdit() {
    //@ts-ignore
    this.$nextTick(() => this.$refs['editText'].focus())
  }
}
