
































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ADORI_API_BASE_URL } from '@/constants'
import { toClockLike, secondsToMmss } from '@/utils/time'
import { Getter } from 'vuex-class'
import VueSlider from 'vue-slider-component'

@Component({ components: { VueSlider } })
export default class AudioPlayer extends Vue {
  @Prop(String) audioUid!: String
  @Prop(Array) tagsInTrack!: any
  @Prop(Number) currentTime!: number
  @Prop(Boolean) isPlaying!: boolean

  @Getter ytModalId!: any
  @Getter selectedEpisodeSettings!: any
  @Getter networkType!: any
  @Getter timeLineZoom!: any
  @Getter isYoutube!: Boolean

  startTimeSec: number = 0
  endTimeSec: number = this.audio.durationMillis / 1000
  tagJumperIndex: number = 0
  currentAudio!: any

  options: any = {
    eventType: 'auto',
    width: 'auto',
    height: 6,
    dotSize: 16,
    dotHeight: null,
    dotWidth: null,
    min: 0,
    interval: 1,
    show: true,
    speed: 0.5,
    disabled: false,
    piecewise: false,
    useKeyboard: true,
    enableCross: true,
    piecewiseLabel: false,
    tooltip: 'none',
    tooltipDir: 'top',
    reverse: false,
    clickable: true,
    realTime: true,
    lazy: false,
    formatter: null,
    bgStyle: null,
    sliderStyle: null,
    processStyle: {
      background: '#DE1A23',
    },
    piecewiseActiveStyle: null,
    tooltipStyle: null,
    labelStyle: null,
    labelActiveStyle: null,
  }

  created() {
    if (this.ytModalId && this.selectedEpisodeSettings[this.ytModalId]) {
      this.startTimeSec = this.selectedEpisodeSettings[this.ytModalId].startTimeSec
      this.endTimeSec = this.selectedEpisodeSettings[this.ytModalId].endTimeSec
    }
    this.currentAudio = new Audio(`${this.audioSrc}#t=${this.startTimeSec},${this.endTimeSec}`)
  }

  previewAudioTrack() {
    this.$store.dispatch('showAudioPreview', this.audioUid)
  }

  togglePlay() {
    if (this.currentAudio.paused) {
      this.currentAudio.play()
    } else {
      this.currentAudio.pause()
    }
    this.$emit('toggle-play', !this.isPlaying)
  }

  get hasTagsInTrack() {
    return this.filteredTagsInTrack && this.filteredTagsInTrack.length === 0 ? false : true
  }

  get filteredTagsInTrack() {
    return this.ytModalId && this.selectedEpisodeSettings[this.ytModalId]
      ? this.tagsInTrack.filter((tag: any) => {
          const offsetMillis = tag.offsetMillis / 1000
          if (
            offsetMillis >= this.selectedEpisodeSettings[this.ytModalId].startTimeSec &&
            offsetMillis <= this.selectedEpisodeSettings[this.ytModalId].endTimeSec
          )
            return tag
        })
      : this.tagsInTrack
  }

  get audio() {
    return this.$store.getters.audio(this.audioUid)
  }

  get audioSrc() {
    const token = this.$store.getters.token
    const networkId = this.$store.getters.networkId
    return `${ADORI_API_BASE_URL}/networks/${networkId}/audiotracks/${this.audioUid}/audio?api_token=${token}`
  }

  get playButtonIcon() {
    return this.isPlaying ? 'pause_circle_outline' : 'play_circle_outline'
  }

  get formattedCurrentTime() {
    return secondsToMmss(this.currentTime)
  }

  get formattedTotalTime() {
    return secondsToMmss(
      Math.ceil(this.ytModalId && this.endTimeSec ? this.endTimeSec : this.audio.durationMillis / 1000)
    )
  }

  handleZoom(value: number) {
    this.$store.commit('setTimeLineZoom', value)
  }

  @Watch('filteredTagsInTrack')
  handleTagsInTrack() {
    if (this.filteredTagsInTrack.length && this.tagJumperIndex === this.filteredTagsInTrack.length) {
      this.tagJumperIndex = this.filteredTagsInTrack.length - 1
      this.$emit('scroll-timeline', this.filteredTagsInTrack[this.tagJumperIndex].offsetMillis / 1000)
    } else if (this.filteredTagsInTrack.length) {
      this.$emit('scroll-timeline', this.filteredTagsInTrack[this.tagJumperIndex - 1].offsetMillis / 1000)
    }
  }

  @Watch('isPlaying')
  onIsPlayingChange(newIsPlaying: boolean) {
    if (!newIsPlaying) {
      this.currentAudio.pause()
      this.currentAudio.removeEventListener('timeupdate')
    } else {
      if (this.ytModalId && this.endTimeSec && this.currentAudio.currentTime === this.endTimeSec) {
        this.currentAudio.currentTime = this.startTimeSec
        this.currentAudio.play()
        this.currentAudio.addEventListener('timeupdate', () => {
          this.$emit('scroll-timeline', this.currentAudio.currentTime)
        })
      } else {
        this.currentAudio.play()
        this.currentAudio.addEventListener('timeupdate', () => {
          this.$emit('scroll-timeline', this.currentAudio.currentTime)
        })
      }
    }
  }

  @Watch('currentTime')
  onTimeChanged(newTime: number) {
    // pause and reset on completion
    if (this.endTimeSec && +newTime.toFixed(2) >= +this.endTimeSec.toFixed(2)) {
      this.currentAudio.pause()
      this.$emit('toggle-play', false)
      this.currentAudio.currentTime = 0
      return
    }

    if (Math.abs(newTime - this.currentAudio.currentTime) > 0.5) {
      this.currentAudio.currentTime = newTime
    }
  }

  get sliderValue() {
    return this.currentTime
  }

  set sliderValue(value: number) {
    this.$emit('scroll-timeline', value)
  }

  dragend(index: any) {
    this.currentAudio.currentTime = index.currentValue
  }
  callback(value: any) {
    this.currentAudio.currentTime = value
  }

  tagJumper(next: boolean) {
    if (next) {
      if (this.tagJumperIndex !== this.filteredTagsInTrack.length) {
        this.$emit('scroll-timeline', this.filteredTagsInTrack[this.tagJumperIndex + 1].offsetMillis / 1000)
        this.filteredTagsInTrack.length - this.tagJumperIndex !== 1 && (this.tagJumperIndex += 1)
      }
    } else {
      if (this.tagJumperIndex >= 1) {
        this.$emit('scroll-timeline', this.filteredTagsInTrack[this.tagJumperIndex - 1].offsetMillis / 1000)
        this.tagJumperIndex -= 1
      }
    }
  }

  seekBackward() {
    if (this.currentAudio.paused && this.currentAudio.currentTime - 10 > this.startTimeSec) {
      this.$emit('scroll-timeline', this.currentAudio.currentTime - 10)
    } else if (this.currentAudio.currentTime - 10 > this.startTimeSec) {
      this.currentAudio.currentTime -= 10
    }
  }
  seekForward() {
    if (this.currentAudio.paused && this.currentAudio.currentTime + 10 < this.endTimeSec) {
      this.$emit('scroll-timeline', this.currentAudio.currentTime + 10)
    } else if (this.currentAudio.currentTime + 10 < this.endTimeSec) {
      this.currentAudio.currentTime += 10
    }
  }

  seek(forward: boolean) {
    const seekedBy = forward ? 10 : -10
    this.$emit('scroll-timeline', this.currentTime + seekedBy)
  }

  beforeDestroy() {
    this.currentAudio.pause()
  }
}
