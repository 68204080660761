var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100" }, [
    _vm.isLoadingSubtitles || _vm.isAudiogramLoading
      ? _c(
          "div",
          _vm._l(new Array(1), function (_, index) {
            return _c("div", {
              key: index,
              staticClass: "bg-adori-light-gray bb bw1 b--adori-gray skeleton",
            })
          }),
          0
        )
      : _c(
          "div",
          [
            _c("SubtitlePreview", {
              attrs: {
                backgroundVideo: _vm.backgroundVideo,
                trackUid: _vm.trackUid,
                currentTime: _vm.currentTime,
                isPlaying: _vm.isPlaying,
                tagsInTrack: _vm.tagsInTrack,
                coverImage: _vm.coverImage,
                audiogramImg: _vm.audiogramImg,
                audiogramVideo: _vm.audiogramVideo,
              },
              on: {
                "toggle-play": _vm.togglePlay,
                "scroll-timeline": _vm.scrollTimeline,
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }