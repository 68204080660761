



























































































































































































































































































import TheHeader from '@/components/Common/TheHeader.vue'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import PublishButton from '@/components/Publish/PublishButton.vue'
import AudioTagger from '@/components/Audio/AudioTagger.vue'
import NewAudioPlayer from '@/components/Audio/NewAudioPlayer.vue'
import { useGetAudioTrack } from '@/hooks/audio'
import { getQueryParam } from '@/utils/misc'
import { Component, Vue, Watch } from 'vue-property-decorator'
import get from 'lodash.get'
import { Action, Getter } from 'vuex-class'
import { uploadStatus } from '@/components/Publish/publish'
import { useGetTags, useGetTrackTags } from '@/hooks/tag'
import { computed, ref } from '@vue/composition-api'
import TheTagCollections from '@/components/Tags/Collections/TheTagCollections.vue'
import TheTagsGrid from '@/components/Tags/TheTagsGrid.vue'
import TheTagSuggestions from '@/components/Tags/Suggestions/TheTagSuggestions.vue'
import TheAudiogramGrid from '@/components/Audiogram/TheAudiogramGrid.vue'
import { useGetTrackAudiograms } from '@/hooks/audiogram'
import TheSubtitlePanel from '@/components/Subtitle/TheSubtitlePanel.vue'
import TheVideoPreview from '@/components/TheVideoPreview.vue'
import { useGetCustomTemplateStyles } from '@/hooks/subtitle'

@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
    PublishButton,
    AudioTagger,
    NewAudioPlayer,
    TheTagCollections,
    TheTagsGrid,
    TheTagSuggestions,
    TheAudiogramGrid,
    TheSubtitlePanel,
    TheVideoPreview,
  },
  setup() {
    const trackUid: any = getQueryParam('uid')
    const orientation = ref('SQUARE')
    const query = ref('')
    const isTrackEnabled = computed(() => !!trackUid)
    const { data: trackTags, isFetching: isTrackTagsLoading } = useGetTrackTags(trackUid, { enabled: isTrackEnabled })
    const { data: trackAudiograms, isFetching: isTrackAudiogramsLoading } = useGetTrackAudiograms(trackUid, {
      enabled: isTrackEnabled,
    })
    const { data: audioData, isFetching: isAudioLoading } = useGetAudioTrack(trackUid)
    useGetTags({ orientation, query })
    useGetCustomTemplateStyles()

    return {
      orientation,
      audioData,
      isAudioLoading,
      trackUid,
      trackTags,
      isTrackTagsLoading,
      trackAudiograms,
      isTrackAudiogramsLoading,
    }
  },
})
export default class ViewAudioEditor extends Vue {
  @Getter networkId!: boolean
  @Getter timelineScroll!: boolean
  @Getter timeLineZoom!: any
  @Getter ytModalId!: any
  @Getter selectedEpisodeSettings!: any
  @Getter youtubeTourShown!: string[]
  @Getter windowOrientation!: string
  @Getter isTimeLineEdited!: string
  @Getter isAutomationLoading!: string

  @Action showAudioTrackEditor!: any
  @Action showReplaceAudio!: any
  @Action publishRssFeed!: any
  @Action deleteAudio!: any
  @Action closeModal!: any
  @Action uploadTourStatus!: any

  isSticky = false
  filter: string = 'SQUARE'
  $shepherd: any
  currentTime = 0
  isPlaying: boolean = false
  currentTab: string = 'TAGS'
  snippetTimeoutHandler: number | undefined
  startTimeSec!: number
  endTimeSec!: number
  isMaximized: boolean = true
  timeLineLoader: boolean = false

  orientation!: string
  trackUid!: string
  audioData!: any
  isAudioLoading!: boolean
  trackTags!: any
  isTrackTagsLoading!: boolean
  nextTagPage!: any
  isNextTagFetching!: boolean
  isTagsLoading!: boolean
  hasNextTags!: boolean
  trackAudiograms!: any
  isTrackAudiogramsLoading!: boolean

  created() {
    this.selectCollection('ALL_TAGS')
    window.addEventListener('beforeunload', this.reload)
  }

  reload(event: BeforeUnloadEvent) {
    event.preventDefault()
    this.$store.dispatch('uploadAudioTags', this.trackUid)
    return (event.returnValue = 'Changes that you made may not be saved')
  }

  mounted() {
    this.$nextTick(this.editorTour)
    window.addEventListener('scroll', this.handleScroll)

    if (this.ytModalId && this.selectedEpisodeSettings[this.ytModalId]) {
      this.startTimeSec = this.selectedEpisodeSettings[this.ytModalId].startTimeSec
      this.endTimeSec = this.selectedEpisodeSettings[this.ytModalId].endTimeSec
    }
  }

  get isFromYoutube() {
    return /^(\/onboarding)/.test(this.$router.currentRoute.fullPath)
  }

  get isFromHomePage() {
    return getQueryParam('homepage')
  }

  get audioPreviewImage() {
    return this.audioData && this.audioData.imageInfo ? this.audioData.imageInfo.url : ''
  }

  get audioName() {
    return get(this.audioData, 'name', 'Loading ...')
  }

  get backgroundVideo() {
    return get(this.audioData, 'videoUrl', '')
  }

  get isPublishPodcastAllowed() {
    if (this.audioCollectionUids.length > 0) {
      for (const feedUid of this.audioCollectionUids) {
        if (this.$permissions.isPublishPodcastShowAllowed(feedUid)) {
          return true
        }
      }
      return false
    } else {
      return this.$permissions.isPublishPodcastAllowed()
    }
  }

  get audioCollectionUids() {
    let feedUids = []
    if (this.audioData && this.audioData.audioCollections) {
      feedUids = this.audioData.audioCollections.map((audioCol: any) => audioCol.uid)
    }
    return feedUids
  }

  get audioStitchTaskStatus() {
    return this.audioData && this.audioData.audioStitchTaskStatus
  }

  get isEditEpisodeAllowed() {
    if (this.audioCollectionUids.length > 0) {
      for (const feedUid of this.audioCollectionUids) {
        if (this.$permissions.isEditEpisodeShowAllowed(feedUid)) {
          return true
        }
      }
      return false
    } else {
      return this.$permissions.isEditEpisodeAllowed()
    }
  }

  get isDeleteEpisodeAllowed() {
    if (this.audioCollectionUids.length > 0) {
      for (const feedUid of this.audioCollectionUids) {
        if (this.$permissions.isDeleteEpisodeShowAllowed(feedUid)) {
          return true
        }
      }
      return false
    } else {
      return this.$permissions.isDeleteEpisodeAllowed()
    }
  }

  get audioIsGettingReplaced() {
    return ![uploadStatus.FINISHED, uploadStatus.FAILED, undefined, null].includes(
      get(this.audioData, 'replaceTask.status')
    )
  }

  get tagPositions() {
    const tagPositions = this.$store.getters.tagPositions(get(this.audioData, 'uid', '')) || []
    return tagPositions
  }

  get audiogramPositions() {
    const audiogramPositions = this.$store.getters.audiogramPositions(this.audioData.uid) || []
    return audiogramPositions
  }

  get currentCollection() {
    return this.$store.getters.selectedTagCollection
  }

  get isInsertTagEpisodeAllowed() {
    if (this.audioCollectionUids.length > 0) {
      for (const feedUid of this.audioCollectionUids) {
        if (this.$permissions.isInsertTagEpisodeShowAllowed(feedUid)) {
          return true
        }
      }
      return false
    } else {
      return true
    }
  }

  onCurrentTime(currentTime: number) {
    this.currentTime = currentTime
    const audioTagger: any = document.getElementById('audioTaggerEl')
    this.timelineScroll && (audioTagger.scrollLeft = (currentTime * 120) / this.timeLineZoom)
  }

  onAudioTaggerScroll(e: any) {
    const s: any = e.srcElement.scrollLeft
    if (this.ytModalId && this.startTimeSec && s < this.startTimeSec * 40)
      e.srcElement.scrollLeft = this.startTimeSec * 40
    if (this.ytModalId && this.endTimeSec && s > this.endTimeSec * 40) e.srcElement.scrollLeft = this.endTimeSec * 40

    this.currentTime = (s / 120) * this.timeLineZoom
  }

  changeTab() {
    this.currentTab = 'SUGGESTED'
    this.selectCollection('TAG_SUGGESTIONS')
  }

  async placeTag(tagItem: any) {
    this.$store.dispatch('addAudioTag', {
      audioUid: this.trackUid,
      tagPosition: tagItem,
    })
    const feedId = get(this.audioData, 'audioCollections[0].uid')

    if (feedId) {
      await this.publishRssFeed({
        rssFeedUid: feedId,
      })
    }
    this.$store.commit('setTimelineEdited', true)
  }
  async placeAudiogram(item: any) {
    this.$store.dispatch('addAudioAudiogram', {
      audioUid: this.trackUid,
      audiogramPosition: item,
    })
    const feedId = get(this.audioData, 'audioCollections[0].uid')
    if (feedId) {
      await this.publishRssFeed({
        rssFeedUid: feedId,
      })
    }
  }

  async selectCollection(collectionName: string) {
    this.$store.dispatch('setSelectedTagCollection', collectionName)
    for (const id of this.$store.getters.tagCollections[collectionName] || []) {
      if (
        !this.$store.getters.allTagsById[id] ||
        (!this.$store.getters.tag(id) && !this.$store.getters.allTagsById[id])
      ) {
        try {
          await this.$store.dispatch('getTag', id)
        } catch (e) {
          this.$store.dispatch('removeTagsFromTagCollection', {
            collectionName: this.currentCollection,
            tagIds: [id],
          })
        }
      }
    }
  }

  playSnippet(offsetMillis: number, duration: number, callback: Function) {
    this.onCurrentTime(offsetMillis / 1000)
    this.isPlaying = true
    this.snippetTimeoutHandler = window.setTimeout(() => {
      this.isPlaying = false
      callback()
    }, duration)
  }

  pauseSnippet() {
    this.isPlaying = false
    if (this.snippetTimeoutHandler) {
      window.clearTimeout(this.snippetTimeoutHandler)
      this.snippetTimeoutHandler = undefined
    }
  }

  async setFilter(value: string) {
    this.orientation = value
    this.selectCollection(this.currentCollection)
  }

  popupMenuItems() {
    return [
      {
        name: 'Preview',
        icon: 'remove_red_eye',
        disabled: this.audioStitchTaskStatus && this.audioStitchTaskStatus !== 'FINISHED',
        onClick: () => this.previewAudioTrack(),
      },
      {
        name: 'Edit track details',
        icon: 'edit',
        disabled:
          !this.isEditEpisodeAllowed || (this.audioStitchTaskStatus && this.audioStitchTaskStatus !== 'FINISHED'),
        onClick: () => this.handleEditAudio(),
      },
      {
        name: 'Delete audio',
        icon: 'delete',
        disabled:
          !this.isDeleteEpisodeAllowed || (this.audioStitchTaskStatus && this.audioStitchTaskStatus !== 'FINISHED'),
        onClick: () => this.handleDeleteAudio(),
      },
      {
        name: 'Replace audio',
        icon: 'swap_vert',
        disabled:
          !this.isEditEpisodeAllowed || (this.audioStitchTaskStatus && this.audioStitchTaskStatus !== 'FINISHED'),
        onClick: () => this.handleReplaceAudio(),
      },
    ]
  }

  previewAudioTrack() {
    this.$store.dispatch('showAudioPreview', this.trackUid)
  }

  handleEditAudio() {
    this.showAudioTrackEditor(this.audioData)
  }

  handleDeleteAudio() {
    if (this.audioData.audioCollections.length === 0) {
      this.$store.dispatch('showConfirm', {
        title: 'Delete audio track?',
        description: 'You are about to permanently delete this audio track. Are you sure?',
        yesButtonText: 'Yes, delete this audio track',
        noButtonText: 'No, keep this audio track',
        onConfirm: () => {
          this.deleteAudio(this.trackUid)
          this.closeModal()
          this.$router.push('/audio')
        },
      })
    } else {
      this.$store.dispatch('showConfirm', {
        title: 'Cannot delete audio track',
        description:
          'You cannot delete this audiotrack as it is part of one or move active podcasts. Please remove it from any podcasts before deleting the audio.',
        yesButtonText: 'Okay, got it',
        onConfirm: () => {
          this.closeModal()
        },
      })
    }
  }

  handleReplaceAudio() {
    this.showReplaceAudio(this.trackUid)
  }

  nextYoutube() {
    this.$store.commit('setYoutubeStep', 2)
    this.$router.push('/onboarding')
  }

  handleScroll(e: any) {
    if (e.target.documentElement.scrollTop > 40) {
      this.isSticky = true
    } else {
      this.isSticky = false
    }
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    if (this.isTimeLineEdited) {
      this.$store.dispatch('uploadAudioTags', this.trackUid)
    }
    window.removeEventListener('beforeunload', this.reload)
  }

  editorTour() {
    const tour = this.$shepherd({
      defaultStepOptions: {
        classes: 'shepherd-theme-custom',
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
      },
      useModalOverlay: true,
    })
    tour.addSteps([
      {
        title: 'Audio Timeline<span>1/3</span>',
        text: `Drag and drop interactive tags or audiogram onto the timeline<video muted autoplay loop ><source  src="https://storage.googleapis.com/dev_audio_adorilabs/samples/tour/timeline.mp4" type="video/mp4" ></video>`,
        attachTo: {
          element: '#audio_timeline_tour_step_1',
          on: 'bottom',
        },
        when: {
          show() {},
        },
        buttons: [
          {
            text: 'Exit tour',
            action: () => {
              tour.cancel()
              this.uploadTourStatus({ tourName: 'audio_timeline_tour', isLoggedIn: this.networkId })
            },
            classes: 'shepherd-back',
          },
          {
            text: 'Next',
            action() {
              tour.next()
            },
            classes: 'shepherd-next',
          },
        ],
        id: 'one',
      },

      {
        title: 'Minimize timeline<span>2/3</span>',
        text: `Minimize timeline to view tags and transcript in fullscreen mode`,
        attachTo: {
          element: '#audio_timeline_tour_step_3',
          on: 'top',
        },
        when: {},
        buttons: [
          {
            text: 'Back',

            action: () => {
              tour.back()
            },
            classes: 'shepherd-back',
          },
          {
            text: 'Next',
            action: () => {
              tour.next()
            },

            classes: 'shepherd-next',
          },
        ],
        id: 'two',
      },
      {
        title: 'AI tags<span>3/3</span>',
        text: `Generate transcript or upload transcript to use powerful NLP powered AI tags<video autoplay loop> <source  src="https://storage.googleapis.com/dev_audio_adorilabs/samples/tour/suggested_tags.mp4" type="video/mp4" ></video>`,
        attachTo: {
          element: '#audio_timeline_tour_step_4',
          on: 'right',
        },
        when: {
          show() {},
          destroy: function () {},
        },

        buttons: [
          {
            text: 'Back',

            action: () => {
              tour.back()
            },
            classes: 'shepherd-back',
          },
          {
            text: 'Done',
            action: () => {
              tour.next()
              this.uploadTourStatus({ tourName: 'audio_timeline_tour', isLoggedIn: this.networkId })
            },

            classes: 'shepherd-next',
          },
        ],
        id: 'three',
      },
    ])

    if (!this.youtubeTourShown.includes('audio_timeline_tour')) {
      tour.start()
    }
  }

  @Watch('isTimeLineEdited')
  handleEdited() {
    if (this.isTimeLineEdited) {
      setTimeout(() => {
        this.$store.dispatch('uploadAudioTags', this.trackUid)
      }, 30000)
    }
  }

  @Watch('windowOrientation')
  onOrientationChange() {
    if (this.windowOrientation && this.windowOrientation !== this.orientation) {
      this.orientation = this.windowOrientation
    }
  }

  @Watch('timeLineZoom')
  handleZoom() {
    this.onCurrentTime(this.currentTime)
  }
}
