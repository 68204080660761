var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex justify-between items-center mt2 unselectable" },
    [
      _c("div", { staticClass: "w5 flex" }, [
        _vm.isYoutube
          ? _c("div", [
              _c(
                "i",
                {
                  staticClass: "material-icons f3 mv1 mh2 v-mid unselectable",
                  class:
                    _vm.timeLineZoom > 1
                      ? "light-gray pointer grow"
                      : "gray not-allowed",
                  on: {
                    click: function ($event) {
                      _vm.timeLineZoom > 1 &&
                        _vm.handleZoom(_vm.timeLineZoom - 1)
                    },
                  },
                },
                [_vm._v("\n        zoom_out\n      ")]
              ),
              _c("span", { staticClass: "white ba ph1 br2" }, [
                _vm._v(" " + _vm._s(_vm.timeLineZoom) + " "),
              ]),
              _c(
                "i",
                {
                  staticClass: "material-icons f3 mv1 mh2 v-mid",
                  class:
                    _vm.timeLineZoom < 3
                      ? "light-gray pointer grow"
                      : "gray not-allowed",
                  on: {
                    click: function ($event) {
                      _vm.timeLineZoom < 3 &&
                        _vm.handleZoom(_vm.timeLineZoom + 1)
                    },
                  },
                },
                [_vm._v("\n        zoom_in\n      ")]
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "br2" }, [
        _c("div", { staticClass: "flex items-center dib light-gray" }, [
          _c("div", { staticClass: "flex items-center mr3" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.formattedCurrentTime + " / " + _vm.formattedTotalTime
                ) +
                "\n      "
            ),
          ]),
          _c("div", { staticClass: "audio-control flex items-center mr3" }, [
            _c(
              "audio",
              { staticClass: "v-mid ml4", attrs: { preload: "auto" } },
              [_c("source", { attrs: { src: _vm.audioSrc } })]
            ),
            _c(
              "i",
              {
                staticClass:
                  "material-icons light-gray f3 mv1 mh2 v-mid pointer grow unselectable",
                on: { click: _vm.seekBackward },
              },
              [_vm._v("\n          replay_10\n        ")]
            ),
            _c(
              "i",
              {
                staticClass:
                  "material-icons light-gray f3 mv1 mh2 v-mid pointer grow unselectable",
                on: { click: _vm.togglePlay },
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.playButtonIcon) + "\n        "
                ),
              ]
            ),
            _c(
              "i",
              {
                staticClass:
                  "material-icons light-gray f3 mv1 mh2 v-mid pointer grow",
                on: { click: _vm.seekForward },
              },
              [_vm._v(" forward_10 ")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "w5" },
            [
              _c(
                "vue-slider",
                _vm._b(
                  {
                    attrs: { min: _vm.startTimeSec, max: _vm.endTimeSec },
                    on: { "drag-end": _vm.dragend, callback: _vm.callback },
                    model: {
                      value: _vm.sliderValue,
                      callback: function ($$v) {
                        _vm.sliderValue = $$v
                      },
                      expression: "sliderValue",
                    },
                  },
                  "vue-slider",
                  _vm.options,
                  false
                )
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm.hasTagsInTrack
        ? _c("div", { staticClass: "flex items-center" }, [
            _c(
              "i",
              {
                staticClass:
                  "material-icons light-gray f3 mv1 mh2 pointer grow",
                on: {
                  click: function ($event) {
                    return _vm.tagJumper(false)
                  },
                },
              },
              [_vm._v(" arrow_circle_left ")]
            ),
            _c("div", { staticClass: "f6 light-gray ph1" }, [
              _vm._v(
                "\n      Tag " +
                  _vm._s(_vm.tagJumperIndex + 1) +
                  " of\n      " +
                  _vm._s(_vm.filteredTagsInTrack.length) +
                  "\n    "
              ),
            ]),
            _c(
              "i",
              {
                staticClass:
                  "material-icons light-gray f3 mv1 mh2 pointer grow",
                on: {
                  click: function ($event) {
                    return _vm.tagJumper(true)
                  },
                },
              },
              [_vm._v(" arrow_circle_right ")]
            ),
          ])
        : _c("div", { staticClass: "w4" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }