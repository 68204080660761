import { render, staticRenderFns } from "./TheTagSuggestions.vue?vue&type=template&id=e08b0dc0&scoped=true&"
import script from "./TheTagSuggestions.vue?vue&type=script&lang=ts&"
export * from "./TheTagSuggestions.vue?vue&type=script&lang=ts&"
import style0 from "./TheTagSuggestions.vue?vue&type=style&index=0&id=e08b0dc0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e08b0dc0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e08b0dc0')) {
      api.createRecord('e08b0dc0', component.options)
    } else {
      api.reload('e08b0dc0', component.options)
    }
    module.hot.accept("./TheTagSuggestions.vue?vue&type=template&id=e08b0dc0&scoped=true&", function () {
      api.rerender('e08b0dc0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Tags/Suggestions/TheTagSuggestions.vue"
export default component.exports