var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 mb3 br2" },
    [
      _vm.isTranscriptLoading || _vm.transcriptInterval
        ? _c(
            "div",
            { staticClass: "flex flex-column justify-center items-center mt5" },
            [
              _c("div", { staticClass: "bar-loader" }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isTranscriptLoading,
                      expression: "isTranscriptLoading",
                    },
                  ],
                  staticClass: "f5 silver lh-copy mt4",
                },
                [_vm._v("Checking for transcript ...")]
              ),
              _vm.transcriptInterval
                ? _c("div", [
                    ["QUEUED", "STARTED"].includes(_vm.transcriptStatus)
                      ? _c("div", { staticClass: "f5 silver lh-copy mt4" }, [
                          _vm._v(
                            "\n        Transcript will appear here once it is ready.\n        "
                          ),
                          _c("span", { staticClass: "ml3 f6 silver mr4" }, [
                            _vm._v("Status: " + _vm._s(_vm.transcriptStatus)),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      !_vm.transcriptInterval &&
      _vm.transcript &&
      _vm.transcript.status === "FAILED"
        ? _c(
            "div",
            { staticClass: "flex flex-column justify-center items-center mt5" },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/transcript.svg"),
                  alt: "",
                  width: "190",
                  height: "190",
                },
              }),
              _vm.transcriptStatus === "FAILED"
                ? _c("div", { staticClass: "f5 silver lh-copy" }, [
                    _vm._v(
                      "\n      Something went wrong. We could not generate a transcript for this audio.\n    "
                    ),
                  ])
                : _vm._e(),
              _c("BaseButtonRed", {
                staticClass: "mt3 inline-block",
                attrs: {
                  text: _vm.uploadTranscriptText,
                  loading: _vm.buttonUploadTranscriptDisabled,
                  disabled: _vm.buttonUploadTranscriptDisabled,
                  onClick: _vm.handleUpload,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.isTranscriptExist
        ? _c("div", { staticClass: "flex flex-column items-center" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/transcript.svg"),
                alt: "",
                width: "190",
                height: "190",
              },
            }),
            _c("div", { staticClass: "f5 silver lh-copy" }, [
              _vm._v(
                "Generate/Upload transcript for your audio to see tag suggestions."
              ),
            ]),
            _c(
              "div",
              [
                _vm.transcriptStatus !== "FINISHED"
                  ? _c("BaseButtonRed", {
                      staticClass: "mt3 inline-block",
                      attrs: {
                        loading: _vm.buttonDisabled,
                        disabled: _vm.buttonDisabled,
                        text: _vm.transcriptStatusText,
                        onClick: _vm.handleGenerate,
                      },
                    })
                  : _vm._e(),
                _vm.transcriptStatus !== "FINISHED" && !_vm.buttonDisabled
                  ? _c("BaseButtonRed", {
                      staticClass: "inline-block",
                      attrs: {
                        text: _vm.uploadTranscriptText,
                        loading: _vm.buttonUploadTranscriptDisabled,
                        disabled: _vm.buttonUploadTranscriptDisabled,
                        onClick: _vm.handleUpload,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.isTranscriptLoading &&
      !_vm.transcriptInterval &&
      _vm.transcriptStatus === "FINISHED"
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "justify-left pb3 overflow-y-scroll-ns scrollbar h-45",
                class: { "h-90": !_vm.isMaximized },
              },
              [
                _vm.sentences.length !== 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "flex justify-between items-center w-100 mb2",
                      },
                      [
                        _c("BaseButtonBorder", {
                          staticClass: "mt2",
                          attrs: {
                            text: _vm.uploadTranscriptText,
                            loading: _vm.buttonUploadTranscriptDisabled,
                            disabled: _vm.buttonUploadTranscriptDisabled,
                            onClick: _vm.handleUpload,
                          },
                        }),
                        _c("BaseButtonRed", {
                          staticClass: "auto-pick mt2 mr2",
                          attrs: {
                            text: "Auto-Pick assets & create tags",
                            icon: "auto_awesome",
                            onClick: _vm.handleAutomation,
                            loading: _vm.isSceneImgFetching,
                            disabled: _vm.isSceneImgFetching,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.sentences.length !== 0
                  ? _c(
                      "div",
                      [
                        _c(
                          "TagSuggestionsTranscriptSelectionMenu",
                          {
                            attrs: {
                              selectedNode: _vm.selectedSuggestionNode,
                              isPlayingSnippet: _vm.isPlayingSnippet,
                            },
                            on: {
                              "play-snippet": _vm.onPlaySnippet,
                              "pause-snippet": _vm.onPauseSnippet,
                              "search-image": _vm.onSearchImage,
                              "create-tag": _vm.onCreateTag,
                              "search-google": _vm.onSearchGoogle,
                            },
                          },
                          _vm._l(_vm.sentences, function (sentence, i) {
                            return _c("div", { key: "trans-sentence-" + i }, [
                              (parseInt(sentence.words[0].start_time) >=
                                +_vm.startTimeSec &&
                                parseInt(sentence.words[0].start_time) <=
                                  +_vm.endTimeSec) ||
                              _vm.endTimeSec === 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "f5 light-gray flex lh-wide select-text",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-10 mr1 mv1 unselectable pointer dim underline",
                                          class: {
                                            highlighted:
                                              _vm.isTimeClicked(sentence),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onTimeClick(
                                                sentence.words,
                                                i + "_paragraph"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "flex justify-center",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formattedTime(
                                                    sentence.words[0].start_time
                                                  )
                                                ) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          ref: i + "_paragraph",
                                          refInFor: true,
                                          staticClass: "w-80 mh2 mv1",
                                        },
                                        _vm._l(
                                          sentence.words,
                                          function (phrase, j) {
                                            return _c(
                                              "span",
                                              {
                                                key: "trans-phrase-" + j,
                                                attrs: {
                                                  "data-start-time":
                                                    phrase.start_time,
                                                  "data-end-time":
                                                    phrase.end_time,
                                                },
                                                on: {
                                                  click:
                                                    _vm.onSuggestionClicked,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(phrase.text) +
                                                    "\n                "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "unselectable material-icons white v-mid pointer ml3 o-icon",
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleBlogImage(
                                                sentence.words,
                                                i + "_paragraph",
                                                sentence.text,
                                                sentence.start_time,
                                                sentence.end_time
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                image\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          }),
                          0
                        ),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "adori-red mt4" }, [
                      _vm._v("No speech was detected in the audiotrack!"),
                    ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm.showContentModal
        ? _c("ModalContent", {
            attrs: {
              context: _vm.selectedContext,
              data: [],
              scene: _vm.selectedScene,
              isSowVideo: _vm.isSowVideo,
              source: "TRANSCRIPT",
            },
            on: {
              closeModal: function ($event) {
                _vm.showContentModal = false
              },
              "load-image": _vm.handleContentLoad,
              "load-video": _vm.handleContentVideoLoad,
            },
          })
        : _vm._e(),
      _vm.showAutomationTypeModal
        ? _c("ModalAutomationType", {
            on: {
              closeModal: function ($event) {
                _vm.showAutomationTypeModal = false
              },
              automate: _vm.automateAITags,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }