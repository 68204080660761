








































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import TagSuggestionsLinkTagPreview from '@/components/Tags/Suggestions/TagSuggestionsLinkTagPreview.vue'
import { removeDuplicatesByProp } from '@/utils/misc'
import AdoriService from '@/services/adori'
import { suggestionSource } from '@/components/Tags/Suggestions/Suggestions'

@Component({ components: { TagSuggestionsLinkTagPreview } })
export default class TagSuggestionsLinks extends Vue {
  @Prop(String) filter!: string
  @Prop() type!: string
  @Prop() title!: string
  @Prop() links!: any
  @Prop(String) audioUid!: string
  @Prop(Number) currentTime!: number
  @Prop(Function) onCurrentTime!: Function
  @Prop() keywordsToBeSearched!: any

  @Action fetchTranscript!: Function
  @Action fetchSuggestions!: Function
  @Action setShowLoader!: any

  @Getter getSuggestions!: Function
  @Getter getTranscript!: Function
  @Getter userId!: string
  @Getter networkId!: string

  selectedLinkTab: string = 'TAGS_VIEW' // 'LINKS_VIEW'

  isAddingTagSuggestionsToTimeline: boolean = true

  get linksWithImages() {
    if (this.keywordsToBeSearched.length) {
      const lowerCase = this.keywordsToBeSearched.map(({ caption }: any) => caption.toLowerCase())

      return this.links.filter(
        (link: any) => lowerCase.includes(link.caption.toLowerCase()) && link.images.length !== 0
      )
    } else {
      return this.links.filter((link: any) => link.images.length !== 0)
    }
  }

  get transcriptSuggestions() {
    return this.getSuggestions(this.audioUid, 'transcript')
  }

  get transcript() {
    return this.getTranscript(this.audioUid)
  }

  handleAddTags() {
    this.$store.dispatch('showConfirm', {
      title: 'Add all suggested tags?',
      description:
        'All the suggested tags are going to be added to the track at the specified time stamps. If any tags already exist on track these tags are going to be added to the nearest available time stamps. Are you sure?',
      yesButtonText: 'Yes, add all tags',
      noButtonText: 'No, do not add tags',
      onConfirm: () => {
        this.addTags()
        this.$store.dispatch('closeModal')
      },
    })
  }

  async addTags() {
    this.setShowLoader(true)
    await this.$store.dispatch('changeAddTagsOfAudioInAudioProcessList', {
      audioUid: this.audioUid,
      addTags: 'ADDING',
    })
    await this.$store.dispatch('fetchTranscript', this.audioUid)
    await this.$store.dispatch('fetchSuggestions', {
      audioUid: this.audioUid,
      source: suggestionSource.EP_INFO,
    })
    await this.$store.dispatch('fetchSuggestions', {
      audioUid: this.audioUid,
      source: suggestionSource.TRANSCRIPT,
    })
    await this.$store.dispatch('addTagSuggestionsToTimeline', this.audioUid)
    await this.$store.dispatch('changeAddTagsOfAudioInAudioProcessList', {
      audioUid: this.audioUid,
      addTags: 'DONE',
    })
    this.setShowLoader(false)
  }
  async addTagSuggestionsToTimeline() {
    this.setShowLoader(true)

    const transcriptSuggestions = this.transcriptSuggestions.suggestions

    const tagSuggestions = removeDuplicatesByProp(
      this.transcriptSuggestions.suggestions.filter((s: any) => s.url),
      'url'
    )
      .filter((s: any) => s.images.length !== 0)
      .map((s: any) => {
        const { sentIdx, wordIdx } = s.contexts[0].beginWord
        const startTimeInSeconds = parseFloat(this.transcript.sentences[sentIdx].words[wordIdx].start_time)

        return {
          url: s.url,
          imageUrl: s.images[0].url,
          caption: '',
          offsetMillis: startTimeInSeconds * 1000,
          suggestionId: s.id,
        }
      })

    const createAllTags = tagSuggestions.map(async (s: any) => {
      const image: any = await AdoriService.uploadImage(this.networkId, {
        url: s.imageUrl,
      })
      const tag = await AdoriService.createTag(this.networkId, {
        actions: 'click',
        url: s.url,
        caption: '',
        imageId: image.id,
        shareable: true,
        saveable: true,
        style: {
          fontStyle: 1,
          topMarginPercentage: 1,
          imageOpacity: 0,
        },
        suggestionId: s.suggestionId,
      })
      return tag
    })
    const allTags = await Promise.all(createAllTags)
    await this.$store.dispatch('addTags', allTags)

    const allTagPositions = allTags.map((tag: any, index: any) => {
      const tagId = tag.id
      const offsetMillis = tagSuggestions[index].offsetMillis
      return {
        tagId,
        offsetMillis,
      }
    })
    await this.$store.dispatch('addAudioTags', {
      audioUid: this.audioUid,
      tagPositions: allTagPositions,
    })

    this.setShowLoader(false)
  }

  get audio() {
    return this.$store.getters.audio(this.audioUid)
  }

  get audioCollectionUids() {
    let feedUids = []
    if (this.audio && this.audio.audioCollections) {
      feedUids = this.audio.audioCollections.map((audioCol: any) => audioCol.uid)
    }
    return feedUids
  }

  get isInsertTagEpisodeAllowed() {
    if (this.audioCollectionUids.length > 0) {
      for (const feedUid of this.audioCollectionUids) {
        if (this.$permissions.isInsertTagEpisodeShowAllowed(feedUid)) {
          return true
        }
      }
      return false
    } else {
      return this.$permissions.isInsertTagEpisodeAllowed()
    }
  }
}
