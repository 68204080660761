var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100 flex" }, [
    _c(
      "div",
      { staticClass: "w-50" },
      [
        _c(
          "div",
          { staticClass: "flex justify-between mt2" },
          [
            _c("BaseButtonBorder", {
              staticClass: "mr3 btn-hover",
              attrs: {
                text: "HH:mm:ss.SSS <-> ms",
                onClick: _vm.changeTimeFormat,
              },
            }),
            _c(
              "div",
              { staticClass: "flex" },
              [
                _vm.hasUnsavedChanges
                  ? _c("span", { staticClass: "flex items-center mr2 gray" }, [
                      _vm._v("Unsaved changes"),
                    ])
                  : _vm._e(),
                _c("BaseButtonBorder", {
                  staticClass: "mr3 btn-hover",
                  class: { "b-red": _vm.hasUnsavedChanges },
                  attrs: {
                    text: _vm.saveText,
                    onClick: _vm.handleSave,
                    loading: _vm.loading,
                    disabled: _vm.loading,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("VirtualList", {
          ref: "list",
          staticClass: "overflow-y-scroll-ns scrollbar h-35",
          class: { "h-60": !_vm.isMaximized },
          attrs: {
            "data-key": "end",
            "data-sources": _vm.subtitles,
            "data-component": _vm.SubtitleBlock,
            "estimate-size": 80,
            "extra-props": {
              trackUid: _vm.trackUid,
              currentTime: _vm.currentTime,
              timeFormat: _vm.timeFormat,
            },
            keeps: 50,
          },
          on: {
            "remove-subtitle": _vm.removeSubtitleLine,
            "add-subtitle": _vm.addSubtitleInBetween,
            "change-highlighted-words": _vm.changeHighlightedWords,
            "change-text-color": _vm.changeTextColor,
            "change-text": _vm.changeText,
            "update-subtitle": _vm.updateSubtitle,
          },
        }),
        _c(
          "div",
          {
            staticClass: "add-new-subtitle",
            on: { click: _vm.addNewSubtitle },
          },
          [
            _c("img", {
              staticClass: "nodrag",
              attrs: { src: require("@/assets/subtitle/add.svg") },
            }),
            _c("span", { staticClass: "new-label" }, [
              _vm._v("Add New Subtitles Line"),
            ]),
          ]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "w-50 ml4" }, [
      _c("div", { staticClass: "flex justify-between mt2" }, [
        _c(
          "div",
          {
            staticClass:
              "ba mb2 pv1 br4 b--transparent bg-adori-light-gray w-custom",
          },
          [
            _c(
              "label",
              {
                staticClass: "pv1 ph2 silver link pointer",
                class:
                  _vm.selectedSubtitleCategory === "OPTIONS"
                    ? "ba br4 b--transparent bg-black-80 light-gray"
                    : "",
              },
              [
                _c("input", {
                  staticClass: "input-reset",
                  attrs: { type: "radio" },
                  domProps: {
                    checked: _vm.selectedSubtitleCategory === "OPTIONS",
                  },
                  on: { click: _vm.handleOptionsTab },
                }),
                _vm._v("Options"),
              ]
            ),
            _c(
              "label",
              {
                staticClass: "pv1 ph2 silver link pointer",
                class:
                  _vm.selectedSubtitleCategory === "STYLES"
                    ? "ba br4 b--transparent bg-black-80 light-gray"
                    : "",
              },
              [
                _c("input", {
                  staticClass: "input-reset",
                  attrs: { type: "radio" },
                  domProps: {
                    checked: _vm.selectedSubtitleCategory === "STYLES",
                  },
                  on: { click: _vm.handleStyleTab },
                }),
                _vm._v("Styles"),
              ]
            ),
          ]
        ),
      ]),
      _vm.selectedSubtitleCategory === "STYLES"
        ? _c("div", { staticClass: "sub-panel" }, [
            _c("div", { staticClass: "subtitle mt2 mb3 flex f6 light-gray" }, [
              _c("span", { staticClass: "gray mr1" }, [
                _vm._v("Selected Style:"),
              ]),
              _vm._v(
                "\n        " +
                  _vm._s(
                    "" +
                      _vm.selectedStyle[0] +
                      _vm.selectedStyle.slice(1).toLowerCase()
                  ) +
                  "\n      "
              ),
            ]),
            _c(
              "div",
              { staticClass: "flex gray" },
              [
                _c("BaseButtonRed", {
                  staticClass: "preview-btn",
                  attrs: {
                    text: "Choose Style",
                    icon: "palette",
                    onClick: _vm.chooseStyle,
                  },
                }),
                _c("BaseTooltip", {
                  staticClass: "pa2",
                  attrs: {
                    info: "Styling from here would be applied to all your text blocks of the subtitles",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.selectedSubtitleCategory === "OPTIONS"
        ? _c("div", { staticClass: "sub-panel" }, [
            _c("div", { staticClass: "silver" }, [_vm._v("Subtitles Source")]),
            _c("div", { staticClass: "mode-selection" }, [
              _c(
                "div",
                {
                  staticClass: "subtitle-mode-btn noselect pointer",
                  on: {
                    click: function ($event) {
                      _vm.toggleAutoSubtitle = !_vm.toggleAutoSubtitle
                    },
                  },
                },
                [_vm._m(0), _vm._m(1)]
              ),
              _vm.toggleAutoSubtitle
                ? _c(
                    "div",
                    {
                      staticClass:
                        "auto-subtitle-collapse flex flex-column justify-center noselect",
                    },
                    [
                      _c("div", { staticClass: "gray f6" }, [
                        _vm._v("Select language to detect"),
                      ]),
                      _c("div", { staticClass: "mt3 scrollbar w5 center" }, [
                        _c("div", { staticClass: "scrollbar relative" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedLanguage,
                                  expression: "selectedLanguage",
                                },
                              ],
                              staticClass:
                                "pointer input-reset light-gray bn br2 pa3 pv2 shadow-2 db arrow w-100 mb2 w2 f6 bg-adori-very-light-gray",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedLanguage = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            _vm._l(_vm.languages, function (lang, i) {
                              return _c(
                                "option",
                                {
                                  key: lang.code,
                                  staticClass: "f6",
                                  staticStyle: { width: "150px" },
                                  domProps: { value: lang.code },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(lang.name) +
                                      "\n                "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _c(
                            "i",
                            {
                              staticClass: "material-icons absolute light-gray",
                              staticStyle: { top: "5px", right: "10px" },
                            },
                            [_vm._v(" arrow_drop_down ")]
                          ),
                        ]),
                      ]),
                      _c("BaseButtonRed", {
                        staticClass:
                          "white mb2 w5 center bg-adori-red bn br2 pv2 ph2 pointer",
                        attrs: {
                          text: _vm.subtitleStatusText,
                          loading: _vm.buttonDisabled,
                          disabled: _vm.buttonDisabled,
                          onClick: _vm.handleGenerate,
                        },
                      }),
                      _c("span", { staticClass: "description" }, [
                        _vm._v(
                          "Subtitles for this audio track will be made available soon, the process may take some time depending on\n            the length and quality of the audio"
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "subtitle-mode-btn noselect",
                  class: _vm.uploadTranscriptStatus ? "not-allowed" : "pointer",
                  on: { click: _vm.handleUpload },
                },
                [
                  _vm._m(2),
                  _c("div", { staticClass: "btn-text" }, [
                    _c("div", { staticClass: "btn-text-main" }, [
                      _vm._v("Upload File"),
                    ]),
                    _vm.uploadTranscriptStatus
                      ? _c("div", { staticClass: "text-loading" }, [
                          _vm._v("Please wait uploading..."),
                        ])
                      : _c("div", { staticClass: "btn-text-sub" }, [
                          _vm._v("Add subtitles from your .SRT file"),
                        ]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "subtitle-mode-btn noselect pointer",
                  on: {
                    click: function ($event) {
                      ;[
                        _vm.addNewSubtitle(),
                        function () {
                          return _vm.$gtag.event("manual-subtitle", {
                            action: "Type your subtitles manually",
                          })
                        },
                      ]
                    },
                  },
                },
                [_vm._m(3), _vm._m(4)]
              ),
            ]),
            _vm.isDownloading
              ? _c("div", { staticClass: "adori-red mt4" }, [
                  _vm._v("Downloading Subtitles. Please wait..."),
                ])
              : _c("div", { staticClass: "silver mt4" }, [
                  _vm._v("Download Subtitles"),
                ]),
            _c("div", { staticClass: "flex mt2" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedFormat,
                      expression: "selectedFormat",
                    },
                  ],
                  staticClass:
                    "bg-adori-gray white f6 w-90 br2 h2 ba b--gray mt1 scrollbar",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedFormat = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.formats, function (item, i) {
                  return _c(
                    "option",
                    { key: i, domProps: { value: item.id } },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.title) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "item ml2", on: { click: _vm.handleDownload } },
                [
                  _c(
                    "i",
                    { staticClass: "f4 material-icons white v-mid fw6" },
                    [_vm._v("download")]
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "a",
        {
          ref: "transcriptDownloadRef",
          staticClass: "dn",
          attrs: { download: "" },
        },
        [_vm._v("Download Subtitles")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-icon" }, [
      _c("img", { attrs: { src: require("@/assets/subtitle/auto-sub.svg") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-text" }, [
      _c("div", { staticClass: "btn-text-main" }, [
        _vm._v("Automatic Subtitle"),
      ]),
      _c("div", { staticClass: "btn-text-sub" }, [
        _vm._v("Auto convert audio to text"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/subtitle/upload-sub.svg") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/subtitle/manual-sub.svg") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-text" }, [
      _c("div", { staticClass: "btn-text-main" }, [_vm._v("Manual Subtile")]),
      _c("div", { staticClass: "btn-text-sub" }, [
        _vm._v("Type your subtitles manually"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }