var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100 mb3 br2" }, [
    _c("div", { staticClass: "flex flex-wrap justify-left" }, [
      _c(
        "div",
        {
          staticClass: "w-50 overflow-y-scroll-ns scrollbar h-45 pb3",
          class: { "h-90": !_vm.isMaximized },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-between items-center" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "ba pv1 br4 b--transparent bg-adori-light-gray mt2",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "pv1 ph2 silver link pointer",
                      class:
                        _vm.selectedTagSuggestionsCategory === "TRANSCRIPT"
                          ? "ba br4 b--transparent bg-black-80 light-gray"
                          : "",
                    },
                    [
                      _c("input", {
                        staticClass: "input-reset",
                        attrs: { type: "radio" },
                        domProps: {
                          checked:
                            _vm.selectedTagSuggestionsCategory === "TRANSCRIPT",
                        },
                        on: { click: _vm.handleTrancriptTab },
                      }),
                      _vm._v("Transcript"),
                    ]
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "pv1 ph2 silver link pointer",
                      class:
                        _vm.selectedTagSuggestionsCategory === "EPISODE_INFO"
                          ? "ba br4 b--transparent bg-black-80 light-gray"
                          : "",
                    },
                    [
                      _c("input", {
                        staticClass: "input-reset",
                        attrs: { type: "radio" },
                        domProps: {
                          checked:
                            _vm.selectedTagSuggestionsCategory ===
                            "EPISODE_INFO",
                        },
                        on: { click: _vm.handleEpisodeTab },
                      }),
                      _vm._v("Episode info"),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c("p", { staticClass: "mb1 gray f6" }, [_vm._v("Episode name")]),
          _c("div", { ref: "episodeName" }, [
            _c("p", { staticClass: "light-gray f5 lh-wide select-text" }, [
              _vm._v(_vm._s(_vm.episodeName)),
            ]),
          ]),
          _c("p", { staticClass: "mb1 gray f6" }, [
            _vm._v("Episode description"),
          ]),
          _c(
            "TagSuggestionsEpisodeSelectionMenu",
            {
              attrs: { selectedNode: _vm.selectedSuggestionNode },
              on: {
                "search-image": _vm.onSearchImage,
                "create-tag": _vm.onCreateTag,
                "search-google": _vm.onSearchGoogle,
              },
            },
            [
              _c("div", {
                ref: "description",
                staticClass: "episode-desc light-gray f5 lh-wide select-text",
                domProps: { innerHTML: _vm._s(_vm.description) },
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "w-50 pl3 overflow-y-hidden overflow-x-hidden scrollbar h-45 relative",
          class: { "h-90": !_vm.isMaximized },
        },
        [
          _c(
            "div",
            { staticClass: "flex justify-between items-center" },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.openAiOption,
                      expression: "openAiOption",
                    },
                  ],
                  staticClass:
                    "bg-adori-light-gray white mt3 f6 ph3 br2 h2 ba b--gray",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.openAiOption = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(Object.keys(_vm.openAiPayload), function (key, i) {
                  return _c("option", { key: i, domProps: { value: key } }, [
                    _vm._v("\n            " + _vm._s(key) + "\n          "),
                  ])
                }),
                0
              ),
              "custom" !== _vm.openAiOption
                ? _c(
                    "BaseNewTooltip",
                    { staticClass: "w-100", attrs: { text: _vm.searchQuery } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-100 ba b--adori-gray br2 ml2 mt3 bg-dark-gray relative",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchQuery,
                                expression: "searchQuery",
                              },
                            ],
                            staticClass:
                              "input-reset bg-dark-gray light-gray h2 bn pv1 br2 pl2 pr4 dib w-100 f6 mr2",
                            attrs: {
                              type: "text",
                              placeholder: "Search free images",
                            },
                            domProps: { value: _vm.searchQuery },
                            on: {
                              keyup: _vm.debouncedSearch,
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.searchQuery = $event.target.value
                              },
                            },
                          }),
                          !_vm.searchQuery
                            ? _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons silver absolute f4",
                                  staticStyle: { top: "6px", right: "5px" },
                                },
                                [_vm._v("search")]
                              )
                            : _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons silver absolute pointer dim f4",
                                  staticStyle: { top: "6px", right: "5px" },
                                  on: { click: _vm.clearSearchQuery },
                                },
                                [_vm._v("close")]
                              ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.selectedTab === "SUGGESTED_IMAGES"
            ? _c(
                "div",
                { staticClass: "pv3" },
                [
                  _c("TagSuggestionsSearchImages", {
                    attrs: {
                      openAiOption: _vm.openAiOption,
                      imageSearchText: _vm.imageSearchText,
                      filter: _vm.filter,
                      isMaximized: _vm.isMaximized,
                      searchText: _vm.selectedSuggestionText,
                      invisibleSearchText: _vm.invisibleSearchText,
                      audioUid: _vm.audioUid,
                      paragraph: _vm.paragraph,
                      tabType: "episode",
                    },
                    on: {
                      clear: _vm.clearAllFilters,
                      search: _vm.setImageSearchText,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }